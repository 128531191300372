import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orderBy } from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { addPersistanceEvent } from "../containers/MyResults/scenarioDataSlice";
import { addRunStatusSnack } from "../features/CurveCount/runStatusSnack";
import { addRunMessage } from "../features/RunMessage/runMessageSnack";
import { ScenarioDataKeys } from "../scenarioDataUtils/scenarioDataUtils";
import { BASE_API_URL, endpointsUrl } from "./endpoints";
import { getValidAccessToken } from "./mongo";
import scenarioMetadataApi from "./scenarioMetadataApi";
import {
  selectScenario,
  setShouldSetMediaGridOptions,
  setShouldSetResultsGrid,
  setShouldSetSalesGridOptions,
} from "./scenarioSlice";
import LogRocket from "logrocket";

function sortNestedChannels(data, keys, sortFieldAggregation, sortDirection) {
  sortDirection = sortDirection?.toUpperCase?.();
  if (sortDirection !== "ASC" || sortDirection !== "DESC") {
    sortDirection = "DESC";
  }
  function _sortNestedChannels(data, level) {
    if (level >= keys.length) return data;
    const levelKey = keys[level];
    const levelGroups = data.reduce(
      (acc, v) => ({
        ...acc,
        ...(v[levelKey] != null && {
          [v[levelKey]]: (acc[v[levelKey]] || []).concat(v),
        }),
      }),
      {}
    );
    const sortedChildren = Object.fromEntries(
      Object.entries(levelGroups).map(([k, childData]) => [
        k,
        {
          data:
            childData.length > 1
              ? _sortNestedChannels(childData, level + 1)
              : childData,
          sortValue: childData.reduce(sortFieldAggregation, 0),
          levelName: childData?.[0]?.[levelKey],
        },
      ])
    );
    return Object.values(sortedChildren)
      .sort((a, b) => {
        // break ties with alphabetical order
        return sortDirection === "ASC"
          ? a.sortValue - b.sortValue || a.levelName.localeCompare(b.levelName)
          : b.sortValue - a.sortValue || a.levelName.localeCompare(b.levelName);
      })
      .flatMap((d) => d.data);
  }
  return _sortNestedChannels(data, 0);
}

const scenarioApi = createApi({
  reducerPath: "scenarioApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  // TODO why is this here. prepareHeaders should be passed to fetchBaseQuery
  prepareHeaders: async (headers, { getState }) => {
    const token = await getValidAccessToken();
    headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
  tagTypes: [
    "ResultsGroup",
    "ResultsItem",
    "MediaGroup",
    "MediaItem",
    "SalesGroup",
    "SalesItem",
  ],
  endpoints: (builder) => ({
    getResultsGroup: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "results/getGroup",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ResultsGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getMediaGroup: builder.query({
      query: ({ userId, scenarioId, target, hierarchy }) => ({
        url: "media/getGroup",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
          // Should always pass hierarchy when constraints are used from result.
          // Not passing has potential for race condition on changed media hierarchy.
          hierarchy,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "MediaGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getSalesGroup: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "sales/getGroup",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "SalesGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getResultsGroupChildren: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "results/getGroupChildren",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) =>
        orderBy(responseData.data, "starting_spend", "desc"),
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ResultsGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getMediaGroupChildren: builder.query({
      query: ({ userId, scenarioId, target, hierarchy }) => ({
        url: "media/getGroupChildren",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
          // Should always pass hierarchy when constraints are used from result.
          // Not passing has potential for race condition on changed media hierarchy.
          hierarchy,
        },
      }),
      transformResponse: (responseData) =>
        orderBy(responseData.data, "starting_spend", "desc"),
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "MediaGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getSalesGroupChildren: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "sales/getGroupChildren",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) =>
        orderBy(responseData.data, "starting_sales", "desc"),
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "SalesGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getResultsItems: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "results/getItems",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ResultsItem",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getMediaItems: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "media/getItems",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "MediaItem",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getSalesItems: builder.query({
      query: ({ userId, scenarioId, target }) => ({
        url: "sales/getItems",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "SalesItem",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getResultsGroups: builder.query({
      query: ({ userId, scenarioId, targets }) => ({
        url: "results/getGroups",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          targets,
        },
      }),
      transformResponse: (responseData, _meta, { hierarchy }) => {
        const { data } = responseData;
        if (hierarchy == null) return data;
        return sortNestedChannels(
          data,
          hierarchy,
          (acc, v) => acc + v.starting_spend
        );
      },
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ResultsGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getMediaGroups: builder.query({
      query: ({ userId, scenarioId, targets, hierarchy }) => ({
        url: "media/getGroups",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          targets,
          // Should always pass hierarchy when constraints are used from result.
          // Not passing has potential for race condition on changed media hierarchy.
          hierarchy,
        },
      }),
      // Proved the hierarchy argument if you want sorting applied
      transformResponse: (responseData, _meta, { hierarchy }) => {
        const { data } = responseData;
        if (hierarchy == null) return data;
        return sortNestedChannels(
          data,
          hierarchy,
          (acc, v) => acc + v.starting_spend
        );
      },
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "MediaGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    getSalesGroups: builder.query({
      query: ({ userId, scenarioId, targets }) => ({
        url: "sales/getGroups",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          targets,
        },
      }),
      transformResponse: (responseData, _meta, { hierarchy }) => {
        const { data } = responseData;
        if (hierarchy == null) return data;
        return sortNestedChannels(
          data,
          hierarchy,
          (acc, v) => acc + v.starting_spend
        );
      },
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "SalesGroup",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
    // MUTATIONS
    updateConstraintGroup: builder.mutation({
      query: ({ updateMap, userId, scenarioId, target, mediaHierarchy }) => ({
        url: "constraints/update/group",
        method: "POST",
        body: {
          updateMap,
          user_id: userId,
          scenario_id: scenarioId,
          target,
          mediaHierarchy,
        },
      }),
      invalidatesTags: (_result, error, { userId, scenarioId }) => {
        if (error?.status === 304) return;
        return [
          {
            type: "MediaGroup",
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
        ];
      },
      async onQueryStarted(
        { updateMap, userId, scenarioId, target, mediaHierarchy },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
        } catch (e) {
          console.error("Set group constraint failed", e);
          enqueueSnackbar("Set constraint failed", {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error("Set group constraint failed"), {
            extra: {
              userId,
              scenarioId,
              updateMap: JSON.stringify(updateMap),
              target: JSON.stringify(target),
              mediaHierarchy: JSON.stringify(mediaHierarchy),
            },
          });
          return;
        }
        updateEvents(
          ScenarioDataKeys.Constraints,
          dispatch,
          target,
          Object.keys(updateMap)
        );
      },
    }),
    updateConstraintItem: builder.mutation({
      query: ({ updateMap, userId, scenarioId, target }) => ({
        url: "constraints/update/item",
        method: "POST",
        body: {
          updateMap,
          user_id: userId,
          scenario_id: scenarioId,
          target,
        },
      }),
      invalidatesTags: (_result, error, { userId, scenarioId }) => {
        if (error?.status === 304) return;
        return [
          {
            type: "MediaItem",
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
        ];
      },
      async onQueryStarted(
        { updateMap, userId, scenarioId, target },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
        } catch (e) {
          console.error("Set item constraint failed", e);
          enqueueSnackbar("Set constraint failed", {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error("Set item constraint failed"), {
            extra: {
              userId,
              scenarioId,
              updateMap: JSON.stringify(updateMap),
              target: JSON.stringify(target),
            },
          });
          return;
        }
        updateEvents(
          ScenarioDataKeys.Constraints,
          dispatch,
          target,
          Object.keys(updateMap)
        );
      },
    }),
    propagateConstraint: builder.mutation({
      query: ({
        userId,
        scenarioId,
        target,
        mediaHierarchy,
        field,
        value,
        isDefaultSet,
        bottomLevelName,
      }) => ({
        url: "constraints/propagate",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
          mediaHierarchy,
          field,
          value,
          isDefaultSet,
          bottomLevelName,
        },
      }),
      invalidatesTags: (
        _result,
        _error,
        { userId, scenarioId, bottomLevelName }
      ) => {
        return [
          {
            type: bottomLevelName !== "period" ? "MediaGroup" : "MediaItem",
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
        ];
      },
      async onQueryStarted(
        {
          userId,
          scenarioId,
          target,
          mediaHierarchy,
          field,
          value,
          isDefaultSet,
          bottomLevelName,
        },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Copy down constraint failed";
          console.error(errorMessage, e);
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              target: JSON.stringify(target),
              mediaHierarchy: JSON.stringify(mediaHierarchy),
              field,
              value,
              isDefaultSet,
              bottomLevelName,
            },
          });
          return;
        }
        updateEvents(ScenarioDataKeys.Constraints, dispatch, target, [
          // "lower_spend",
          // "upper_spend",
          "starting_spend", // incorrectly inform that a starting_spend has occurred to force a reload of children too
        ]);
      },
    }),
    updateGroup: builder.mutation({
      query: ({
        collectionName,
        updateMap,
        userId,
        scenarioId,
        target,
        period,
      }) => {
        if (collectionName === "constraints") {
          throw new Error("Don't use updateGroup for constraints");
        }
        return {
          url: "scenario/updateGroup",
          method: "POST",
          body: {
            collectionName,
            updateMap,
            user_id: userId,
            scenario_id: scenarioId,
            target,
            period,
          },
        };
      },
      invalidatesTags: (
        _result,
        error,
        { userId, scenarioId, collectionName, period }
      ) => {
        if (error?.status === 304) return;

        const type = (() => {
          if (collectionName === "sales")
            return period == null ? "SalesGroup" : "SalesItem";
          if (collectionName === "media")
            return period == null ? "MediaGroup" : "MediaItem";
        })();

        if (type == null) return;
        return [
          {
            type,
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
        ];
      },
      async onQueryStarted(
        { userId, scenarioId, collectionName, updateMap, target, period },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set value failed";
          console.error(errorMessage, e);
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              collectionName,
              updateMap: JSON.stringify(updateMap),
              target: JSON.stringify(target),
              period,
            },
          });
          return;
        }
        if (collectionName === "media") {
          updateEvents(
            ScenarioDataKeys.Media,
            dispatch,
            target,
            Object.keys(updateMap)
          );
        }
        if (collectionName === "sales") {
          updateEvents(
            ScenarioDataKeys.Sales,
            dispatch,
            target,
            Object.keys(updateMap)
          );
        }
      },
    }),
    runScenario: builder.mutation({
      query: ({ scenarioId, userId }) => ({
        url: BASE_API_URL.concat("media-mix-optimization/"),
        // url: "http://localhost:5000/media-mix-optimization/",
        method: "POST",
        body: {
          scenario_id: scenarioId,
          user_id: userId,
        },
      }),
      invalidatesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ResultsGroup",
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
          {
            type: "ResultsItem",
            id: JSON.stringify({
              userId,
              scenarioId,
            }),
          },
        ];
      },
      async onQueryStarted(
        { scenarioId, userId },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const {
          id: currentScenarioId,
          aspiration,
          mediaHierarchy,
          resultsHierarchy,
        } = selectScenario(state);

        let snackKey = await addRunStatusSnack({
          scenarioId,
          userId,
          ...(scenarioId === currentScenarioId && {
            aspiration,
            mediaHierarchy,
            resultsHierarchy,
          }),
          dispatch,
        });

        const res = await queryFulfilled;
        if (res.data?.status != null) {
          const { status } = res.data;
          addRunMessage({ status });
          if (status === 0 || status === 1) {
            dispatch(setShouldSetResultsGrid(true));
          }
        }
        if (snackKey) {
          setTimeout(() => closeSnackbar(snackKey), 2000);
        }
      },
    }),
  }),
});

function updateEvents(key, dispatch, target, changedFields) {
  if (key === ScenarioDataKeys.Media || key === ScenarioDataKeys.Constraints) {
    dispatch(
      setShouldSetMediaGridOptions({
        shortTarget: target,
        changedFields,
      })
    );
    dispatch(
      addPersistanceEvent({
        key: ScenarioDataKeys.Media,
        target,
        changedFields,
      })
    );
  }
  if (key === ScenarioDataKeys.Sales) {
    dispatch(
      setShouldSetSalesGridOptions({
        shortTarget: target,
        changedFields,
      })
    );
    dispatch(
      addPersistanceEvent({
        key: ScenarioDataKeys.Sales,
        target,
        changedFields,
      })
    );
  }
}

export default scenarioApi;

export const {
  endpoints,

  useGetResultsGroupQuery,
  useGetMediaGroupQuery,
  useGetSalesGroupQuery,

  useLazyGetResultsGroupQuery,
  useLazyGetMediaGroupQuery,
  useLazyGetSalesGroupQuery,

  useLazyGetResultsGroupChildrenQuery,
  useLazyGetMediaGroupChildrenQuery,
  useLazyGetSalesGroupChildrenQuery,

  useGetResultsItemsQuery,
  useGetMediaItemsQuery,
  useGetSalesItemsQuery,

  useLazyGetResultsItemsQuery,
  useLazyGetMediaItemsQuery,
  useLazyGetSalesItemsQuery,

  useGetResultsGroupsQuery,
  useGetMediaGroupsQuery,
  useGetSalesGroupsQuery,

  useRunScenarioMutation,
  useUpdateGroupMutation,
  useUpdateConstraintGroupMutation,
  useUpdateConstraintItemMutation,
  usePropagateConstraintMutation,
} = scenarioApi;
