import {
  createListenerMiddleware,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  fullTargetToShortTarget,
  getChildTarget,
  getNewNode,
  getNewShortTarget,
  getShortName,
  initializeChannelTree,
  nodeHasChildren,
} from "../utils/channelTreeUtils";
import { objectMap } from "../utils/objectUtil";
import { getNewTarget, isMediaKey, isSuperTarget } from "../utils/targetUtils";
import scenarioMetadataApi from "./scenarioMetadataApi";
import { sortChannels } from "../utils/sortChannels";

// TODO channel tree code needs heavy refactoring. Probably could also use root node data

const resultsChannelTreeInitialData = {
  expanded: false,
};
const mediaChannelTreeInitialData = {
  expanded: false,
  selected: true,
};
const salesChannelTreeInitialData = {
  expanded: false,
  selected: true,
};

export const slice = createSlice({
  name: "scenario",
  initialState: {
    isSwitchingScenario: true,
    isCreatingScenario: false,
    isEmptyScenario: true,
    isScenarioReady: false,
  },
  reducers: {
    addIsSwitchingScenario: (
      state,
      { payload: { id, isCreatingScenario = false } }
    ) => {
      // will stay true if no scenario selected until one is
      state.isSwitchingScenario = true;
      state.isCreatingScenario = isCreatingScenario;
      state.switchingToId = id;
      state.isScenarioReady = false;
      state.switchingState = {};
    },
    addIsDoneCreatingScenario: (state) => {
      state.isCreatingScenario = false;
    },
    changeToEmptyScenario: (state, { payload: id }) => {
      if (id !== state.switchingToId) return; // abort change when another change is queued
      state.isSwitchingScenario = true;
      state.isCreatingScenario = false;
      state.switchingToId = null;
      state.isEmptyScenario = true;
      state.isScenarioReady = false;
      // set everything to empty values so the old values don't accidentally get used
      state.id = 0;
      state.userId = null;
      state.name = null;
      state.aspiration = null;
      state.selectedChart = null;
      state.isSideControlsOpen = null;
      state.selectedSideControl = null;
      state.flighting = null;
      state.growthTarget = null;
      state.mediaBudget = null;
      state.resultsChannels = null;
      state.mediaChannels = null;
      state.salesChannels = null;
      state.resultsHierarchy = null;
      state.mediaHierarchy = null;
      state.salesHierarchy = null;
      state.selectedMediaChannels = null;
      state.resultsExpansionKeys = null;
      state.mediaTreeNodes = null;
      state.salesTreeNodes = null;
      state.shouldSetResultsGrid = false;
      state.shouldSetMediaGridOptions = null;
      state.shouldSetSalesGridOptions = null;
    },
    changeScenario: (
      state,
      {
        payload: {
          id,
          userId,
          name,
          aspiration,
          selectedChart,
          isSideControlsOpen,
          selectedSideControl,
          flighting,
          mediaBudget,
          growthTarget,
          resultsChannels,
          mediaChannels,
          salesChannels,
          mediaHierarchy,
          salesHierarchy,
          selectedMediaChannels,
          resultsExpansionKeys,
        },
      }
    ) => {
      if (id !== state.switchingToId) return;
      state.isSwitchingScenario = false;
      state.isCreatingScenario = false;
      state.switchingToId = null;
      state.isEmptyScenario = false;
      state.isScenarioReady = true;
      state.id = id;
      state.userId = userId;
      state.name = name;
      state.aspiration = aspiration;
      state.selectedChart = selectedChart;
      state.isSideControlsOpen = isSideControlsOpen;
      state.selectedSideControl = selectedSideControl;
      state.flighting = flighting;
      state.growthTarget = growthTarget;
      state.mediaBudget = mediaBudget;
      state.resultsChannels = resultsChannels;
      state.mediaChannels = mediaChannels;
      state.salesChannels = salesChannels;
      state.resultsHierarchy = [...mediaHierarchy, ...salesHierarchy];
      state.mediaHierarchy = mediaHierarchy ?? [];
      state.salesHierarchy = salesHierarchy ?? [];
      state.resultsTreeNodes = initializeChannelTree(state.resultsHierarchy);
      state.mediaTreeNodes = initializeChannelTree(state.mediaHierarchy);
      state.salesTreeNodes = initializeChannelTree(state.salesHierarchy);
      addNodesFromFullTargetsHelper(
        state,
        "resultsTreeNodes",
        "resultsHierarchy",
        resultsChannels,
        (_target, id, levelKey) => {
          if (!isMediaKey(levelKey)) {
            return {
              expanded: false,
            };
          }
          if (resultsExpansionKeys[levelKey].includes(id)) {
            return {
              expanded: true,
            };
          }
          return {
            expanded: false,
          };
        }
      );
      addNodesFromFullTargetsHelper(
        state,
        "mediaTreeNodes",
        "mediaHierarchy",
        mediaChannels,
        selectedMediaChannels == null
          ? mediaChannelTreeInitialData
          : (target) => ({
              expanded: false,
              selected:
                selectedMediaChannels.find((fullTarget) =>
                  isSuperTarget(target, fullTarget)
                ) != null,
            })
      );
      state.selectedMediaChannels =
        selectedMediaChannels ??
        sortChannels(
          Object.values(state.mediaTreeNodes[state.mediaHierarchy.at(-1)])
            .filter(({ data: { selected } }) => selected)
            .map(({ target }) => target),
          state.mediaHierarchy
        );
      addNodesFromFullTargetsHelper(
        state,
        "salesTreeNodes",
        "salesHierarchy",
        salesChannels,
        salesChannelTreeInitialData
      );
      state.shouldSetResultsGrid = false;
      state.shouldSetMediaGridOptions = null;
      state.shouldSetSalesGridOptions = null;
      Object.entries(state.switchingState).forEach(([k, v]) => {
        state[k] = v;
      });
      state.switchingState = {};
    },
    setScenarioName: (state, { payload }) => {
      state.name = payload;
    },
    setSelectedChart: (state, { payload }) => {
      state.selectedChart = payload;
    },
    setIsSideControlsOpen: (state, { payload }) => {
      state.isSideControlsOpen = payload;
    },
    setSelectedSideControl: (state, { payload }) => {
      state.selectedSideControl = payload;
    },
    setShouldSetResultsGrid: (state, { payload }) => {
      state.shouldSetResultsGrid = payload;
    },
    setShouldSetMediaGridOptions: (state, { payload }) => {
      state.shouldSetMediaGridOptions = payload;
    },
    setShouldSetSalesGridOptions: (state, { payload }) => {
      state.shouldSetSalesGridOptions = payload;
    },
    setMediaHierarchy: (state, { payload }) => {
      state.mediaHierarchy = payload;
      state.resultsHierarchy = [
        ...state.mediaHierarchy,
        ...state.salesHierarchy,
      ];
      state.resultsTreeNodes = initializeChannelTree(state.resultsHierarchy);
      state.mediaTreeNodes = initializeChannelTree(state.mediaHierarchy);
      addNodesFromFullTargetsHelper(
        state,
        "resultsTreeNodes",
        "resultsHierarchy",
        state.resultsChannels,
        resultsChannelTreeInitialData
      );
      addNodesFromFullTargetsHelper(
        state,
        "mediaTreeNodes",
        "mediaHierarchy",
        state.mediaChannels,
        (target) => ({
          expanded: false,
          selected:
            state.selectedMediaChannels.find((fullTarget) =>
              isSuperTarget(target, fullTarget)
            ) != null,
        })
      );
    },
    setSalesHierarchy: (state, { payload }) => {
      state.salesHierarchy = payload;
      state.resultsHierarchy = [
        ...state.mediaHierarchy,
        ...state.salesHierarchy,
      ];
      state.resultsTreeNodes = initializeChannelTree(state.resultsHierarchy);
      state.salesTreeNodes = initializeChannelTree(state.salesHierarchy);
      addNodesFromFullTargetsHelper(
        state,
        "resultsTreeNodes",
        "resultsHierarchy",
        state.resultsChannels,
        resultsChannelTreeInitialData
      );
      addNodesFromFullTargetsHelper(
        state,
        "salesTreeNodes",
        "salesHierarchy",
        state.salesChannels,
        salesChannelTreeInitialData
      );
    },
    setMediaBudget: (state, { payload }) => {
      state.mediaBudget = payload;
    },
    setGrowthTarget: (state, { payload }) => {
      state.growthTarget = payload;
    },
    setSelectedMediaChannels: (state, { payload }) => {
      state.selectedMediaChannels = payload;

      forEachChannelTreeNode(
        state.mediaTreeNodes,
        state.mediaHierarchy,
        Object.values(state.mediaTreeNodes[state.mediaHierarchy[0]]),
        (node) => {
          const newSelected =
            payload.find((fullTarget) =>
              isSuperTarget(node.target, fullTarget)
            ) != null;
          if (newSelected === false && node.data.selected === false) {
            return false;
          }
          node.data.selected = newSelected;
        }
      );
    },
    // editing or getting to root node is undefined behavior
    addResultsTreeNode: addChannelTreeNodeWrapper(
      "resultsTreeNodes",
      "resultsHierarchy"
    ),
    addMediaTreeNode: addChannelTreeNodeWrapper(
      "mediaTreeNodes",
      "mediaHierarchy"
    ),
    addSalesTreeNode: addChannelTreeNodeWrapper(
      "salesTreeNodes",
      "salesHierarchy"
    ),
    updateResultsTreeNodeData: updateChannelTreeNodeDataWrapper(
      "resultsTreeNodes",
      "resultsHierarchy"
    ),
    resetResultsTreeExpansion: (state) => {
      forEachChannelTreeNode(
        state.resultsTreeNodes,
        state.resultsHierarchy,
        Object.values(state.resultsTreeNodes[state.mediaHierarchy[0]]),
        (node) => {
          node.data.expanded = false;
        }
      );
    },
    updateMediaTreeNodeData: (...args) => {
      const [
        state,
        {
          payload: { shortTarget, data, updateDescendents = false },
        },
      ] = args;
      // console.log(shortTarget, data, updateDescendents);
      updateChannelTreeNodeDataWrapper(
        "mediaTreeNodes",
        "mediaHierarchy"
      )(...args);
      // console.log(JSON.parse(JSON.stringify(state)));
      const newSelected = sortChannels(
        Object.values(state.mediaTreeNodes[state.mediaHierarchy.at(-1)])
          .filter(({ data: { selected } }) => selected)
          .map(({ target }) => target),
        state.mediaHierarchy
      );
      // console.log(JSON.parse(JSON.stringify(newSelected)));
      state.selectedMediaChannels = newSelected;
    },
    updateSalesTreeNodeData: updateChannelTreeNodeDataWrapper(
      "salesTreeNodes",
      "salesHierarchy"
    ),
    addToSwitchingState: (state, { payload }) => {
      // pass is an array of [k, v] or array of [[k1, v1], [k2, v2], ...]
      payload
        .flat()
        .reduce(
          (acc, _, i, arr) =>
            i % 2 == 0 ? [...acc, arr.slice(i, i + 2)] : acc,
          []
        )
        .forEach(([k, v]) => {
          state.switchingState[k] = v;
        });
    },
  },
});

export const changeScenarioListener = createListenerMiddleware();

// update selectedChart after change scenario if there was a selectedChart entry in switching state
changeScenarioListener.startListening({
  predicate: (action, _currentState, previousState) => {
    return (
      action.type == slice.actions.changeScenario.type &&
      previousState.scenario.switchingState.selectedChart != null
    );
  },
  effect: async (_action, listenerApi) => {
    const state = listenerApi.getState();
    const { id: scenarioId, userId, selectedChart } = state.scenario;
    listenerApi.dispatch(
      scenarioMetadataApi.endpoints.setSelectedChart.initiate({
        userId,
        scenarioId,
        selectedChart,
      })
    );
  },
});
changeScenarioListener.startListening({
  predicate: (action, _currentState, previousState) => {
    return (
      action.type == slice.actions.changeScenario.type &&
      previousState.scenario.switchingState.isSideControlsOpen != null
    );
  },
  effect: async (_action, listenerApi) => {
    const state = listenerApi.getState();
    const { id: scenarioId, userId, isSideControlsOpen } = state.scenario;
    listenerApi.dispatch(
      scenarioMetadataApi.endpoints.setIsSideControlsOpen.initiate({
        userId,
        scenarioId,
        isSideControlsOpen,
      })
    );
  },
});
changeScenarioListener.startListening({
  predicate: (action, _currentState, previousState) => {
    return (
      action.type == slice.actions.changeScenario.type &&
      previousState.scenario.switchingState.selectedSideControl != null
    );
  },
  effect: async (_action, listenerApi) => {
    const state = listenerApi.getState();
    const { id: scenarioId, userId, selectedSideControl } = state.scenario;
    listenerApi.dispatch(
      scenarioMetadataApi.endpoints.setSelectedSideControl.initiate({
        userId,
        scenarioId,
        selectedSideControl,
      })
    );
  },
});

function addNodesFromFullTargetsHelper(
  state,
  treeNodesKey,
  hierarchyKey,
  fullTargets,
  initialData
) {
  const hierarchy = state[hierarchyKey];
  const addNodeWrapper = addChannelTreeNodeWrapper(treeNodesKey, hierarchyKey);
  const addNode = (shortTarget, initialData) =>
    addNodeWrapper(state, {
      payload: { shortTarget, initialData },
    });
  fullTargets.forEach((fullTarget) => {
    const shortTarget = fullTargetToShortTarget(fullTarget, hierarchy);
    addNode(shortTarget, initialData);
  });
}

function addChannelTreeNodeWrapper(treeNodesKey, hierarchyKey) {
  return (state, { payload: { shortTarget, initialData } }) => {
    const hierarchy = state[hierarchyKey];
    const nodeKeys = hierarchy.filter((k) => shortTarget[k] !== undefined);
    let previousParentNode;
    nodeKeys.forEach((levelKey, levelKeyIndex) => {
      const currentTarget = getNewShortTarget(
        hierarchy.slice(0, levelKeyIndex + 1),
        shortTarget
      );
      const currentId = getShortName(hierarchy, currentTarget);
      const currentNode = state[treeNodesKey][levelKey][currentId];
      if (currentNode === undefined) {
        const newNodeData =
          initialData instanceof Function
            ? initialData(currentTarget, currentId, levelKey)
            : initialData;
        const newNode = getNewNode(hierarchy, currentTarget, newNodeData);
        state[treeNodesKey][levelKey][newNode.id] = newNode;
        if (previousParentNode != null) {
          previousParentNode.children[newNode.levelId] = newNode.id;
        }
        previousParentNode = newNode;
      } else {
        previousParentNode = currentNode;
      }
    });
  };
}

function updateChannelTreeNodeDataWrapper(treeNodesKey, hierarchyKey) {
  return (
    state,
    { payload: { shortTarget, data, updateDescendents = false } }
  ) => {
    // console.log(treeNodesKey, hierarchyKey, shortTarget, data);
    const hierarchy = state[hierarchyKey];
    function handel(shortTarget, data) {
      const node = getChannelTreeNodeHelper(
        state[treeNodesKey],
        hierarchy
      )(shortTarget);
      if (node == null) {
        console.error(
          "Could find node to update: " + getShortName(hierarchy, shortTarget),
          { shortTarget, data, updateDescendents }
        );
        return null;
      }
      node.data = {
        ...node.data,
        ...data,
      };
      if (updateDescendents) {
        Object.entries(node.children).forEach(([levelId, fullId]) => {
          const childTarget = getChildTarget(hierarchy, shortTarget, levelId);
          handel(childTarget, data);
        });
      }
    }
    handel(shortTarget, data);
  };
}

function getChannelTreeNodeHelper(treeNodes, treeKeys) {
  return (shortTarget) => {
    if (treeNodes == null) return null;
    const path = treeKeys.reduce((acc, k) => {
      if (shortTarget[k] !== undefined) {
        return acc.concat(shortTarget[k]);
      }
      return acc;
    }, []);

    const rootLevelKey = treeKeys[0];
    let node = {
      target: {},
      id: "root",
      children: objectMap(treeNodes[rootLevelKey], ([, node]) => [
        node.target[rootLevelKey],
        node.id,
      ]),
    };
    for (let i = 0; i < path.length; i++) {
      const levelKey = treeKeys[i];
      const childId = node.children[path[i]];
      node = treeNodes[levelKey][childId];
      if (node === undefined) return;
    }

    return node;
  };
}

export const {
  addIsSwitchingScenario,
  addIsDoneCreatingScenario,
  changeToEmptyScenario,
  changeScenario,
  setScenarioName,
  setSelectedChart,
  setIsSideControlsOpen,
  setSelectedSideControl,
  setShouldSetResultsGrid,
  setShouldSetMediaGridOptions,
  setShouldSetSalesGridOptions,
  setMediaBudget,
  setGrowthTarget,
  setMediaHierarchy,
  setSalesHierarchy,
  setSelectedMediaChannels,
  addResultsTreeNode,
  addMediaTreeNode,
  addSalesTreeNode,
  updateResultsTreeNodeData,
  resetResultsTreeExpansion,
  updateMediaTreeNodeData,
  updateSalesTreeNodeData,
  addToSwitchingState,
} = slice.actions;

export const selectScenario = (state) => state[slice.name];
export const selectIsScenarioReady = (state) =>
  state[slice.name].isScenarioReady;
export const selectResultsTreeNodes = (state) =>
  state[slice.name].resultsTreeNodes;
export const selectMediaTreeNodes = (state) => state[slice.name].mediaTreeNodes;
export const selectSalesTreeNodes = (state) => state[slice.name].salesTreeNodes;
export const selectResultsHierarchy = (state) =>
  state[slice.name].resultsHierarchy;
export const selectMediaHierarchy = (state) => state[slice.name].mediaHierarchy;
export const selectSalesHierarchy = (state) => state[slice.name].salesHierarchy;
export const selectSelectedChart = (state) => state[slice.name].selectedChart;
export const selectIsSideControlsOpen = (state) =>
  state[slice.name].isSideControlsOpen;
export const selectSelectedSideControl = (state) =>
  state[slice.name].selectedSideControl;

// export const selectMediaTreeNodeByTarget = getChannelTreeNodeWrapper(
//   "mediaTreeNodes",
//   ALL_MEDIA_CHANNEL_KEYS
// );

// export const selectSalesTreeNodeByTarget = getChannelTreeNodeWrapper(
//   "salesTreeNodes",
//   ALL_SALES_CHANNEL_KEYS
// );

function getChildrenNodes(treeNodes, treeKeys, node) {
  const childrenLevelKey = treeKeys[Object.keys(node.target).length];
  if (childrenLevelKey == null) return {};
  return objectMap(node.children, ([, childId]) => [
    childId,
    treeNodes[childrenLevelKey][childId],
  ]);
}

// export const selectExpansionData = createSelector(
//   [selectMediaTreeNodes, selectIsScenarioReady],
//   (mediaTreeNodes, isScenarioReady) => {
//     if (mediaTreeNodes == null || !isScenarioReady) return null;
//     function nodesToExpansionLevel(nodes) {
//       return Object.values(nodes).reduce((acc, node) => {
//         return {
//           ...acc,
//           [node.levelId]: (() => {
//             if (node.data.expanded !== true) return null;
//             const value = nodesToExpansionLevel(
//               getChildrenNodes(mediaTreeNodes, ALL_MEDIA_CHANNEL_KEYS, node)
//             );
//             return Object.keys(value).length === 0 ? null : value;
//           })(),
//         };
//       }, {});
//     }
//     return nodesToExpansionLevel(mediaTreeNodes[MediaChannel.One]);
//   }
// );

function forEachChannelTreeNode(treeNodes, treeKeys, nodesList, cbFunction) {
  nodesList.forEach((node) => {
    if (cbFunction(node) === false) return;
    forEachChannelTreeNode(
      treeNodes,
      treeKeys,
      Object.values(getChildrenNodes(treeNodes, treeKeys, node)),
      cbFunction
    );
  });
}

// Will only return full targets
function selectAllSelectedTargetsHelper(treeNodes, isScenarioReady, hierarchy) {
  if (treeNodes == null || !isScenarioReady || !hierarchy?.length) return null;
  const result = [];
  forEachChannelTreeNode(
    treeNodes,
    hierarchy,
    Object.values(treeNodes[hierarchy[0]]),
    (node) => {
      if (nodeHasChildren(node)) {
        return;
      }
      if (node.data.selected) {
        result.push(getNewTarget(node.target, hierarchy));
      }
      return node.data.selected;
    }
  );
  return result;
}

export const selectAllSelectedMediaTargets = createSelector(
  [selectMediaTreeNodes, selectIsScenarioReady, selectMediaHierarchy],
  selectAllSelectedTargetsHelper
);

export const selectAllSelectedSalesTargets = createSelector(
  [selectSalesTreeNodes, selectIsScenarioReady, selectSalesHierarchy],
  selectAllSelectedTargetsHelper
);

// Does not have overlapping targets
export const selectExpandedAndSelectedShortMediaTargets = createSelector(
  [
    selectResultsTreeNodes,
    selectMediaTreeNodes,
    selectIsScenarioReady,
    selectMediaHierarchy,
  ],
  (resultsTreeNodes, mediaTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (resultsTreeNodes == null || mediaTreeNodes == null || !isScenarioReady)
      return null;
    const result = [];
    forEachChannelTreeNode(
      resultsTreeNodes,
      mediaHierarchy,
      Object.values(resultsTreeNodes[mediaHierarchy[0]]),
      (node) => {
        if (Object.values(node.target).some((v) => v == null)) {
          // stop branch when given non-short targets from results tree
          return false;
        }
        const mediaTreeNode = getChannelTreeNodeHelper(
          mediaTreeNodes,
          mediaHierarchy
        )(node.target);
        if (
          mediaTreeNode.data.selected &&
          !(node.data.expanded && nodeHasChildren(mediaTreeNode))
        ) {
          result.push(fullTargetToShortTarget(node.target, mediaHierarchy));
        }
        return mediaTreeNode.data.selected && node.data.expanded;
      }
    );
    return result;
  }
);

function selectGetNodeDataHelper(treeNodes, treeKeys) {
  return (target) => {
    const node = getChannelTreeNodeHelper(treeNodes, treeKeys)(target);
    if (node == null) {
      console.error("Null node", target, treeNodes);
    }
    return node?.data ?? { expanded: false, selected: false };
  };
}

export const selectGetResultsTreeNodeData = createSelector(
  [selectResultsTreeNodes, selectIsScenarioReady, selectResultsHierarchy],
  (resultsTreeNodes, isScenarioReady, resultsHierarchy) => {
    if (
      resultsTreeNodes == null ||
      !isScenarioReady ||
      !resultsHierarchy?.length
    )
      return null;

    return selectGetNodeDataHelper(resultsTreeNodes, resultsHierarchy);
  }
);

export const selectGetMediaTreeNodeData = createSelector(
  [selectMediaTreeNodes, selectIsScenarioReady, selectMediaHierarchy],
  (mediaTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (mediaTreeNodes == null || !isScenarioReady || !mediaHierarchy?.length)
      return null;

    return selectGetNodeDataHelper(mediaTreeNodes, mediaHierarchy);
  }
);

export const selectGetSalesTreeNodeData = createSelector(
  [selectSalesTreeNodes, selectIsScenarioReady, selectSalesHierarchy],
  (salesTreeNodes, isScenarioReady, salesHierarchy) => {
    if (salesTreeNodes == null || !isScenarioReady || !salesHierarchy?.length)
      return null;

    return selectGetNodeDataHelper(salesTreeNodes, salesHierarchy);
  }
);

function selectGetNestedChildrenTargetsHelper(treeNodes, treeKeys) {
  return (startTarget) => {
    const startNode = getChannelTreeNodeHelper(
      treeNodes,
      treeKeys
    )(startTarget);
    const result = [];
    forEachChannelTreeNode(
      treeNodes,
      treeKeys,
      Object.values(getChildrenNodes(treeNodes, treeKeys, startNode)),
      (node) => {
        result.push(fullTargetToShortTarget(node.target, treeKeys));
      }
    );
    return result;
  };
}

export const selectGetNestedResultsChildrenTargets = createSelector(
  [selectResultsTreeNodes, selectIsScenarioReady, selectResultsHierarchy],
  (resultsTreeNodes, isScenarioReady, resultsHierarchy) => {
    if (
      resultsTreeNodes == null ||
      !isScenarioReady ||
      !resultsHierarchy?.length
    )
      return null;

    return selectGetNestedChildrenTargetsHelper(
      resultsTreeNodes,
      resultsHierarchy
    );
  }
);

export const selectGetNestedMediaChildrenTargets = createSelector(
  [selectMediaTreeNodes, selectIsScenarioReady, selectMediaHierarchy],
  (mediaTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (mediaTreeNodes == null || !isScenarioReady || !mediaHierarchy?.length)
      return null;

    return selectGetNestedChildrenTargetsHelper(mediaTreeNodes, mediaHierarchy);
  }
);

export const selectGetNestedSalesChildrenTargets = createSelector(
  [selectSalesTreeNodes, selectIsScenarioReady, selectSalesHierarchy],
  (salesTreeNodes, isScenarioReady, salesHierarchy) => {
    if (salesTreeNodes == null || !isScenarioReady || !salesHierarchy?.length)
      return null;

    return selectGetNestedChildrenTargetsHelper(salesTreeNodes, salesHierarchy);
  }
);

export const selectExpandedShortMediaTargets = createSelector(
  [selectResultsTreeNodes, selectMediaHierarchy],
  (resultsTreeNodes, mediaTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (resultsTreeNodes == null || mediaTreeNodes == null || !isScenarioReady)
      return null;
    const result = [];
    forEachChannelTreeNode(
      resultsTreeNodes,
      mediaHierarchy,
      Object.values(resultsTreeNodes[mediaHierarchy[0]]),
      (node) => {
        if (Object.values(node.target).some((v) => v == null)) {
          // stop branch when given non-short targets from results tree
          return false;
        }
        const mediaTreeNode = getChannelTreeNodeHelper(
          mediaTreeNodes,
          mediaHierarchy
        )(node.target);
        if (node.data.expanded && nodeHasChildren(mediaTreeNode)) {
          result.push(fullTargetToShortTarget(node.target, mediaHierarchy));
        }
        return node.data.expanded;
      }
    );
    return result;
  }
);

export const selectExpandedShortMediaTargetsWithoutShortCircuit =
  createSelector(
    [
      selectResultsTreeNodes,
      selectMediaTreeNodes,
      selectIsScenarioReady,
      selectMediaHierarchy,
    ],
    (resultsTreeNodes, mediaTreeNodes, isScenarioReady, mediaHierarchy) => {
      if (
        resultsTreeNodes == null ||
        mediaTreeNodes == null ||
        !isScenarioReady
      )
        return null;
      const result = [];
      forEachChannelTreeNode(
        resultsTreeNodes,
        mediaHierarchy,
        Object.values(resultsTreeNodes[mediaHierarchy[0]]),
        (node) => {
          if (Object.values(node.target).some((v) => v == null)) {
            // stop branch when given non-short targets from results tree
            return false;
          }
          const mediaTreeNode = getChannelTreeNodeHelper(
            mediaTreeNodes,
            mediaHierarchy
          )(node.target);
          if (node.data.expanded && nodeHasChildren(mediaTreeNode)) {
            result.push(fullTargetToShortTarget(node.target, mediaHierarchy));
          }
        }
      );
      return result;
    }
  );

export const selectExpandedResultsTreeNodeIds = createSelector(
  [selectResultsTreeNodes, selectIsScenarioReady, selectMediaHierarchy],
  (resultsTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (resultsTreeNodes == null || !isScenarioReady) {
      return null;
    }
    const result = {};
    for (const mediaKey of mediaHierarchy) {
      result[mediaKey] = [];
      for (const [key, node] of Object.entries(resultsTreeNodes[mediaKey])) {
        if (node.data.expanded) {
          result[mediaKey].push(key);
        }
      }
    }
    return result;
  }
);

export const selectCanContractMediaChannels = createSelector(
  [selectResultsTreeNodes, selectIsScenarioReady, selectMediaHierarchy],
  (resultsTreeNodes, isScenarioReady, mediaHierarchy) => {
    if (resultsTreeNodes == null || !isScenarioReady) {
      return false;
    }
    for (const mediaKey of mediaHierarchy) {
      for (const [_, node] of Object.entries(resultsTreeNodes[mediaKey])) {
        if (node.data.expanded) {
          return true;
        }
      }
    }
    return false;
  }
);

export default slice;
