import { Channel } from "../types/channel";

export function sortChannels(channels: Channel[], hierarchy: string[]) {
  return channels.sort((a, b) => {
    for (const k of hierarchy) {
      if (a[k] == null) return -1;
      if (b[k] == null) return 1;
      if (a[k] !== b[k]) return a[k] > b[k] ? 1 : -1;
    }
  });
}
