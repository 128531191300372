import * as am4core from "@amcharts/amcharts4/core";
import { addLicense as am4AddLicense } from "@amcharts/amcharts4/core";
import { addLicense as am5AddLicense } from "@amcharts/amcharts5";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import {
  useSetIsSideControlsOpenMutation,
  useSetMediaChannelExpansionKeysMutation,
  useSetSelectedChartMutation,
  useSetSelectedSideControlMutation,
} from "../../app/scenarioMetadataApi";
import {
  addToSwitchingState,
  selectExpandedResultsTreeNodeIds,
  selectExpandedShortMediaTargets,
  selectExpandedShortMediaTargetsWithoutShortCircuit,
  selectGetNestedResultsChildrenTargets,
  selectResultsTreeNodes,
  selectScenario,
} from "../../app/scenarioSlice";
import Header from "../../components/Header/Header";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import TabbedContent from "../../components/TabbedContent/TabbedContent";
import GranularityControls from "../../features/SideControls/GranularityControls";
import SelectionAndExpansionControls from "../../features/SideControls/SelectionAndExpansionControls";
import SpendControls from "../../features/SideControls/SpendControls";
import { GridKeyEnum } from "../../scenarioDataUtils/scenarioDataUtils";
import ChartControls from "./ChartControls";
import CurvesChart from "./CurvesChart/CurvesChart";
import MediaGrid from "./MediaGrid";
import PlanChart from "./PlanChart/PlanChart";
import ResultsDetailChart from "./ResultsDetailChart";
import ResultsGrid from "./ResultsGrid";
import ResultsSummaryChart from "./ResultsSummaryChart";
import ResultsWaterfallChart from "./ResultsWaterfallChart";
import SalesGrid from "./SalesGrid";
import { useVariableValue } from "@devcycle/react-client-sdk";

am4AddLicense("CH368908636");
am5AddLicense("AM5C368908636");
am4core.options.autoDispose = true;

const headerHeight = 64;
const tabsHeight = 50; // TODO fix. it's actually 49 and the other px is on the bottom
const presentationLogoHeight = 64;
const bottomPadding = 24;
const chartContainerHeight = {
  min: 300 + tabsHeight,
  max: 520 + tabsHeight,
  spacing: headerHeight,
};
const gridHeight = {
  min: 319,
  max: "100%",
  spacing: headerHeight + bottomPadding,
};

const sideControlsHeightStyles = {
  height: `calc(100vh - ${chartContainerHeight.spacing}px - ${tabsHeight}px - 1em)`,
  minHeight: chartContainerHeight.min - tabsHeight,
  maxHeight: chartContainerHeight.max - tabsHeight,
};
const sideControlsHeightStylesWithTabs = {
  height: `calc(100vh - ${chartContainerHeight.spacing}px - 1em)`,
  minHeight: chartContainerHeight.min,
  maxHeight: chartContainerHeight.max,
};

function SideControlsWrapper({ children }) {
  return (
    <Box
      sx={{
        paddingBottom: "1em",
        ...sideControlsHeightStyles,
        borderRadius: "10px !important",
        display: "flex",
        flexDirection: "column",
        overflowX: "clip",
        overflowY: "auto",
      }}
      variant="outlined"
    >
      {children}
    </Box>
  );
}

const ChartKeyEnum = {
  Summary: "summary",
  Detail: "detail",
  Story: "waterfall",
  Plan: "plan",
  Tactic: "tactic",
  PresentationModeResultsGrid: "presentationModeResultsGrid",
};

const SideControlsKeyEnum = {
  Spend: "spend",
  Levels: "levels",
  SelectionAndExpansion: "selection-and-expansion",
};

const MyResults = () => {
  const selectionAndExpansionControlsFlag = useVariableValue(
    "selection-and-expansion-controls",
    false
  );

  const dispatch = useDispatch();
  const {
    id: scenarioId,
    userId,
    isScenarioReady,
    switchingToId,
    name: scenarioName,
    aspiration,
    selectedChart,
    isSideControlsOpen: _isSideControlsOpen,
    selectedSideControl,
  } = useSelector(selectScenario);

  const [chartTab, setChartTab] = useState(0);
  const [setSelectedChart] = useSetSelectedChartMutation();
  // chartTab and presentationModeChartTab are synced unless presentationModeChartTab is a presentation mode only tab
  const [presentationModeChartTab, setPresentationModeChartTab] = useState(0);
  const [sideControlsTab, setSideControlsTab] = useState(0);
  const [setSelectedSideControl] = useSetSelectedSideControlMutation();
  const [isSideControlsOpen, setIsSideControlsOpen] = useState(0);
  const [setIsSideControlsOpenMutation] = useSetIsSideControlsOpenMutation();
  const [gridTab, setGridTab] = useState(0);

  const [chartsData, setChartsData] = useState(
    Object.values(ChartKeyEnum).reduce(
      (acc, k) => ({
        ...acc,
        [k]: {
          seriesData: null, // the data used to construct the series of the chart.
          metadata: null, // the data about the chart (current shown series, ect.). Includes any data that needs to be persisted on tab change
        },
      }),
      {}
    )
  );

  const presentationModeFullscreenHandler = useFullScreenHandle();
  const presentationModePopOverContainerRef = useRef();

  const isInPresentationMode = useMemo(() => {
    return presentationModeFullscreenHandler.active;
  }, [presentationModeFullscreenHandler.active]);

  function setChartSeriesData(chartKey, seriesData) {
    setChartsData((prevState) => ({
      ...prevState,
      [chartKey]: {
        ...prevState[chartKey],
        seriesData:
          typeof seriesData === "function"
            ? seriesData(prevState[chartKey].seriesData)
            : seriesData,
      },
    }));
  }

  function setChartMetadata(chartKey, metadata) {
    setChartsData((prevState) => ({
      ...prevState,
      [chartKey]: {
        ...prevState[chartKey],
        metadata:
          typeof metadata === "function"
            ? metadata(prevState[chartKey].metadata)
            : metadata,
      },
    }));
  }

  const presentationModeOnlyChartTabs = [
    ChartKeyEnum.PresentationModeResultsGrid,
  ];

  // Decides ordering of the tabs.
  // Maps tab indexes stored in `chartTab` to the tab key used in the db.
  const selectedChartKeys = [
    ChartKeyEnum.Summary,
    ChartKeyEnum.Detail,
    ChartKeyEnum.Story,
    ChartKeyEnum.Plan,
    ChartKeyEnum.Tactic,
    ...(isInPresentationMode ? presentationModeOnlyChartTabs : []),
  ];

  function getChartTabIndex(key) {
    for (let index = 0; index < selectedChartKeys.length; index++) {
      if (selectedChartKeys[index] === key) return index;
    }
    return 0;
  }

  function getChartTabKey(index) {
    return selectedChartKeys[index] ? selectedChartKeys[index] : null;
  }

  const sideControlKeys = [
    SideControlsKeyEnum.Spend,
    SideControlsKeyEnum.SelectionAndExpansion,
    SideControlsKeyEnum.Levels,
  ];

  function getSideControlsTabIndex(key) {
    for (let index = 0; index < sideControlKeys.length; index++) {
      if (sideControlKeys[index] === key) return index;
    }
    return 0;
  }

  function getSideControlsTabKey(index) {
    return sideControlKeys[index] ? sideControlKeys[index] : null;
  }

  function wrapTabContent(getContent, sizingOptions) {
    return <Box sx={sizingOptions}>{getContent()}</Box>;
  }

  function wrapChart(getContent) {
    return wrapTabContent(getContent, {
      margin: "0 1em",
      paddingBottom: "1em",
      height: `calc(100vh - ${presentationLogoHeight}px - ${tabsHeight}px)`,
      ...(!isInPresentationMode && {
        height: `calc(100vh - ${chartContainerHeight.spacing}px - ${tabsHeight}px - 1em)`,
        minHeight: chartContainerHeight.min - tabsHeight,
        maxHeight: chartContainerHeight.max - tabsHeight,
      }),
    });
  }

  function wrapGrid(getContent) {
    return wrapTabContent(getContent, {
      minHeight: gridHeight.min,
      maxHeight: gridHeight.max,
      height: `calc(100vh - ${gridHeight.spacing}px)`,
    });
  }

  const tabsData = [];

  function addChartToTabsData(ChartTag, label, key, other) {
    tabsData.push({
      label,
      key,
      content: (() => {
        let content = (
          <ChartTag
            data={chartsData[key].seriesData}
            setData={(data) => setChartSeriesData(key, data)}
            metadata={chartsData[key].metadata}
            setMetadata={(metadata) => setChartMetadata(key, metadata)}
            isInPresentationMode={isInPresentationMode}
            enterPresentationMode={presentationModeFullscreenHandler.enter}
            exitPresentationMode={presentationModeFullscreenHandler.exit}
            presentationModePopOverContainer={
              presentationModePopOverContainerRef.current
            }
            {...other}
            {...(key === ChartKeyEnum.PresentationModeResultsGrid && {
              sx: {
                height: "100%",
                width: "100%",
              },
            })}
          />
        );
        if (key === ChartKeyEnum.PresentationModeResultsGrid) {
          // add title to results grid
          // content.sx = {height: "100%", width: "100%"};
          const chartOffset = 80; // px
          content = (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Box
                sx={{
                  height: "100%",
                  width: "calc(100% - 2em)",
                  margin: "0 1em",
                }}
              >
                <ChartControls
                  presentationModePopOverContainer={
                    presentationModePopOverContainerRef.current
                  }
                  isInPresentationMode={isInPresentationMode}
                  enterPresentationMode={
                    presentationModeFullscreenHandler.enter
                  }
                  exitPresentationMode={presentationModeFullscreenHandler.exit}
                ></ChartControls>
                <Box
                  sx={{
                    height: `${chartOffset}px`,
                    width: "100px",
                    margin: "0 auto",
                    pt: "30px",
                  }}
                >
                  <Typography
                    component={"div"}
                    sx={{ fontSize: 20, whiteSpace: "nowrap" }}
                  >
                    Results Table
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: `calc(100% - ${chartOffset}px)`,
                    width: "100%",
                  }}
                >
                  {content}
                </Box>
              </Box>
            </Box>
          );
        }

        return wrapChart(() => content);
      })(),
    });
  }

  addChartToTabsData(ResultsSummaryChart, "Summary", ChartKeyEnum.Summary);
  addChartToTabsData(ResultsDetailChart, "Detail", ChartKeyEnum.Detail);
  addChartToTabsData(ResultsWaterfallChart, "Story", ChartKeyEnum.Story);
  addChartToTabsData(PlanChart, "Series", ChartKeyEnum.Plan);
  addChartToTabsData(CurvesChart, "Curves", ChartKeyEnum.Tactic);
  if (isInPresentationMode) {
    addChartToTabsData(
      ResultsGrid,
      "Results",
      ChartKeyEnum.PresentationModeResultsGrid,
      {
        gridKey: GridKeyEnum.Results,
        isInPresentationMode,
        isPresenter: true,
      }
    );
  }

  tabsData.sort((item1, item2) => {
    return getChartTabIndex(item1.key) - getChartTabIndex(item2.key);
  });

  const gridTabsData = [];
  const gridTabToKeyMap = {};

  function addGridToTabsData(GridTag, label, key, gridOptions) {
    gridTabsData.push({
      label,
      key,
      content: (
        <>
          {wrapGrid(() => (
            <LoadingSection
              isLoading={!isScenarioReady}
              type={LoadingType.Cover}
            >
              <GridTag {...gridOptions} gridKey={key} />
            </LoadingSection>
          ))}
        </>
      ),
    });
    gridTabToKeyMap[gridTabsData.length - 1] = key;
  }

  addGridToTabsData(ResultsGrid, "Results", GridKeyEnum.Results, {
    isInPresentationMode,
    isPresenter: false,
  });

  addGridToTabsData(MediaGrid, "Media", GridKeyEnum.Media);

  addGridToTabsData(SalesGrid, "Sales", GridKeyEnum.Sales);

  const sideControlsTabsData = [
    {
      label: <TuneRoundedIcon />,
      key: SideControlsKeyEnum.Spend,
      content: (
        <SideControlsWrapper>
          <SpendControls />
        </SideControlsWrapper>
      ),
    },
    {
      label: (
        <FormatListBulletedRoundedIcon sx={{ transform: "scale(-1, 1)" }} />
      ),
      key: SideControlsKeyEnum.Levels,
      content: (
        <SideControlsWrapper>
          <GranularityControls />
        </SideControlsWrapper>
      ),
    },
    ...(selectionAndExpansionControlsFlag
      ? [
          {
            label: <ChecklistRoundedIcon />,
            key: SideControlsKeyEnum.SelectionAndExpansion,
            content: (
              <SideControlsWrapper>
                <SelectionAndExpansionControls />
              </SideControlsWrapper>
            ),
          },
        ]
      : []),
  ].sort((item1, item2) => {
    return (
      getSideControlsTabIndex(item1.key) - getSideControlsTabIndex(item2.key)
    );
  });

  function setChartTabHandler(newIndex) {
    const selectedChart = getChartTabKey(newIndex);
    if (scenarioId == null) {
      console.warn("scenario is unavailable");
      return;
    } else if (isScenarioReady) {
      setSelectedChart({ userId, scenarioId, selectedChart });
    } else {
      dispatch(addToSwitchingState(["selectedChart", selectedChart]));
    }
  }

  function setGridTabHandler(newIndex) {
    setGridTab(newIndex);
  }

  function setSideControlsTabHandler(newIndex) {
    const selectedSideControl = getSideControlsTabKey(newIndex);
    if (scenarioId == null) {
      console.warn("scenario is unavailable");
      return;
    } else if (isScenarioReady) {
      setSelectedSideControl({ userId, scenarioId, selectedSideControl });
    } else {
      dispatch(
        addToSwitchingState(["selectedSideControl", selectedSideControl])
      );
    }
  }

  function setIsSideControlsOpenHandler(isSideControlsOpen) {
    if (scenarioId == null) {
      console.warn("scenario is unavailable");
      return;
    } else if (isScenarioReady) {
      setIsSideControlsOpenMutation({ userId, scenarioId, isSideControlsOpen });
    } else {
      dispatch(addToSwitchingState(["isSideControlsOpen", isSideControlsOpen]));
    }
  }

  const expandedMediaChannels = useSelector(
    selectExpandedShortMediaTargetsWithoutShortCircuit
  );

  const resultTreeNodes = useSelector(selectResultsTreeNodes);
  const expandedResultsTreeNodeIds = useSelector(
    selectExpandedResultsTreeNodeIds
  );

  const [setMediaChannelExpansionKeys] =
    useSetMediaChannelExpansionKeysMutation();

  useEffect(() => {
    if (expandedResultsTreeNodeIds == null) {
      return;
    }
    setMediaChannelExpansionKeys({
      userId,
      scenarioId,
      expansionKeys: expandedResultsTreeNodeIds,
    });
  }, [expandedResultsTreeNodeIds]);

  useEffect(() => {
    if (!isInPresentationMode) {
      setPresentationModeChartTab(chartTab);
    }
  }, [isInPresentationMode, chartTab]);

  useEffect(() => {
    if (isScenarioReady && selectedChart != null) {
      const chartTabIndex = getChartTabIndex(selectedChart);
      setPresentationModeChartTab(chartTabIndex);
      if (presentationModeOnlyChartTabs.includes(selectedChart)) {
        return;
      }
      setChartTab(chartTabIndex);
    }
  }, [isScenarioReady, selectedChart]);

  useEffect(() => {
    if (isScenarioReady && selectedSideControl != null) {
      setSideControlsTab(getSideControlsTabIndex(selectedSideControl));
    }
  }, [isScenarioReady, selectedSideControl]);

  useEffect(() => {
    if (isScenarioReady) {
      setIsSideControlsOpen(_isSideControlsOpen ?? true);
    }
  }, [isScenarioReady, _isSideControlsOpen]);

  // update chart sizing
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isSideControlsOpen]);

  return (
    <Box>
      <Header title="My Insights" subtitle="drive your business results">
        {isScenarioReady ? scenarioName || "" : ""}
      </Header>

      <Box mt={2.5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                minWidth: "1126px", // 800px for chats + 300 for side controls + boarders + spacing
              }}
            >
              <Box sx={{ flexGrow: 2 }}>
                <FullScreen handle={presentationModeFullscreenHandler}>
                  <TabbedContent
                    id={"results-charts"}
                    value={
                      isInPresentationMode ? presentationModeChartTab : chartTab
                    }
                    setValue={setChartTabHandler}
                    ariaLabel={"Chart Selection"}
                    variant="scrollable"
                    tabsData={tabsData}
                    isInPresentationMode={isInPresentationMode}
                    ref={presentationModePopOverContainerRef}
                  >
                    {isInPresentationMode ? (
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Box
                          item
                          component="img"
                          src="Logo_White_Horizontal.svg"
                          alt="Optimetry"
                          pb={1.5}
                          sx={{
                            height: `${presentationLogoHeight}px`,
                            maxWidth: "90vw",
                            filter: "brightness(90%)", // use https://codepen.io/sosuke/pen/Pjoqqp for more complicated colors
                          }}
                        ></Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </TabbedContent>
                </FullScreen>
              </Box>
              {/* Side Controls */}
              <Box
                sx={{
                  ...(isSideControlsOpen && {
                    flexGrow: 1,
                    flexBasis: "300px",
                    minWidth: "300px",
                    maxWidth: "400px",
                  }),
                }}
              >
                <Box sx={sideControlsHeightStylesWithTabs}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {/* Pull Tab */}
                    <Paper
                      sx={{
                        borderRadius: "10px 0 0 10px",
                        borderRight: "none",
                      }}
                      variant="outlined"
                    >
                      <Button
                        variant="text"
                        color="inherit"
                        sx={{
                          borderRadius: "10px 0 0 10px",
                          padding: 0,
                          minWidth: 0,
                          height: "56px",
                          width: "23px",
                        }}
                        onClick={() =>
                          setIsSideControlsOpenHandler(!isSideControlsOpen)
                        }
                      >
                        {isSideControlsOpen ? (
                          <ChevronRightIcon />
                        ) : (
                          <ChevronLeftIcon />
                        )}
                      </Button>
                    </Paper>
                    {isSideControlsOpen ? (
                      <TabbedContent
                        id={"side-controls"}
                        value={sideControlsTab}
                        setValue={setSideControlsTabHandler}
                        ariaLabel={"Controls Selection"}
                        variant="scrollable"
                        tabsData={sideControlsTabsData}
                        tabMinWidth={"100px"}
                      />
                    ) : (
                      <Paper
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                        variant="outlined"
                      >
                        <List sx={{ py: 0 }}>
                          {sideControlsTabsData.map(({ key, label }, index) => (
                            <Fragment key={key}>
                              <ListItem
                                disablePadding
                                disableGutters
                                sx={{
                                  py: 1,
                                }}
                              >
                                <ListItemButton
                                  selected={sideControlsTab === index}
                                  onClick={(event) => {
                                    setSideControlsTabHandler(index);
                                    setIsSideControlsOpenHandler(true);
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      ...(sideControlsTab === index && {
                                        color: "#29abe2",
                                      }),
                                    }}
                                  >
                                    {label}
                                  </ListItemIcon>
                                </ListItemButton>
                              </ListItem>
                              <Divider />
                            </Fragment>
                          ))}
                        </List>
                      </Paper>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabbedContent
              id={"results-grids"}
              value={gridTab}
              setValue={setGridTabHandler}
              ariaLabel={"Grid Selection"}
              variant="scrollable"
              tabsData={gridTabsData}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MyResults;
