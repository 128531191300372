import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import LogRocket from "logrocket";
import { enqueueSnackbar } from "notistack";
import { endpointsUrl } from "./endpoints";
import {
  selectIsSideControlsOpen,
  selectMediaHierarchy,
  selectSalesHierarchy,
  selectScenario,
  selectSelectedChart,
  selectSelectedSideControl,
  setGrowthTarget,
  setIsSideControlsOpen,
  setMediaBudget,
  setMediaHierarchy,
  setSalesHierarchy,
  setSelectedChart,
  setSelectedSideControl,
  setSelectedMediaChannels,
} from "./scenarioSlice";

export const scenarioMetadataApi = createApi({
  reducerPath: "scenarioMetadataApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["ScenarioMetadata"],
  endpoints: (builder) => ({
    getScenario: builder.query({
      query: ({ userId, scenarioId }) => ({
        url: "scenarios/get",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      transformResponse: (responseData) => {
        const {
          scenario_id,
          scenario_name,
          results_selected_chart,
          growth_target,
          media_budget,
          mediaHierarchy,
          salesHierarchy,
          side_controls_open,
          selected_side_control,
          selectedMediaChannels,
          ...other
        } = responseData.data;
        return {
          // default to empty expansion in case scenario doesn't have it
          expansionKeys: Object.fromEntries(mediaHierarchy.map((k) => [k, []])),
          ...other,
          scenarioId: scenario_id,
          name: scenario_name,
          selectedChart: results_selected_chart,
          growthTarget: growth_target,
          mediaBudget: media_budget,
          resultsHierarchy: [...mediaHierarchy, ...salesHierarchy],
          mediaHierarchy,
          salesHierarchy,
          isSideControlsOpen: side_controls_open,
          selectedSideControl: selected_side_control,
          selectedMediaChannels,
        };
      },
      providesTags: (_result, _error, { scenarioId }) => {
        if (scenarioId)
          return [
            { type: "ScenarioMetadata", id: scenarioId },
            "ScenarioMetadata",
          ];
        return ["ScenarioMetadata"];
      },
    }),

    setMediaHierarchy: builder.mutation({
      query: ({ userId, scenarioId, mediaHierarchy }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(mediaHierarchy && { mediaHierarchy }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, mediaHierarchy },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const currentMediaHierarchy = selectMediaHierarchy(state);
        if (state.scenario.id === scenarioId) {
          // assumes hierarchy length does not change
          if (!currentMediaHierarchy.every((v, i) => v === mediaHierarchy[i])) {
            dispatch(setMediaHierarchy(mediaHierarchy));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set media hierarchy failed";
          console.error(errorMessage, e);
          dispatch(setMediaHierarchy(currentMediaHierarchy));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              mediaHierarchy,
              currentMediaHierarchy,
            },
          });
        }
      },
    }),

    setSalesHierarchy: builder.mutation({
      query: ({ userId, scenarioId, salesHierarchy }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(salesHierarchy && { salesHierarchy }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, salesHierarchy },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const currentSalesHierarchy = selectSalesHierarchy(state);
        if (state.scenario.id === scenarioId) {
          // assumes hierarchy length does not change
          if (!currentSalesHierarchy.every((v, i) => v === salesHierarchy[i])) {
            dispatch(setSalesHierarchy(salesHierarchy));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set sales hierarchy failed";
          console.error(errorMessage, e);
          dispatch(setSalesHierarchy(currentSalesHierarchy));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              salesHierarchy,
              currentSalesHierarchy,
            },
          });
        }
      },
    }),

    setSelectedChart: builder.mutation({
      query: ({ userId, scenarioId, selectedChart }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(selectedChart && { results_selected_chart: selectedChart }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, selectedChart },
        { dispatch, getState, queryFulfilled }
      ) {
        if (scenarioId == null) {
          console.error("Cannot call setSelectedChart without a scenario id");
        }
        const state = getState();
        const isScenarioReady = state.scenario.isScenarioReady;
        if (!isScenarioReady) {
          console.error(
            "Cannot call setSelectedChart while scenario slice is not ready"
          );
        }
        const currentSelectedChart = selectSelectedChart(state);
        if (isScenarioReady && scenarioId === state.scenario.id) {
          if (currentSelectedChart !== selectedChart) {
            dispatch(setSelectedChart(selectedChart));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Persist selected chart failed";
          console.error(errorMessage, e);
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              selectedChart,
              currentSelectedChart,
            },
          });
        }
      },
    }),

    setIsSideControlsOpen: builder.mutation({
      query: ({ userId, scenarioId, isSideControlsOpen }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(isSideControlsOpen != null && {
              side_controls_open: isSideControlsOpen,
            }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, isSideControlsOpen },
        { dispatch, getState, queryFulfilled }
      ) {
        if (scenarioId == null) {
          console.error(
            "Cannot call setIsSideControlsOpen without a scenario id"
          );
        }
        const state = getState();
        const isScenarioReady = state.scenario.isScenarioReady;
        if (!isScenarioReady) {
          console.error(
            "Cannot call setIsSideControlsOpen while scenario slice is not ready"
          );
        }
        const currentIsSideControlsOpen = selectIsSideControlsOpen(state);
        if (isScenarioReady && scenarioId === state.scenario.id) {
          if (currentIsSideControlsOpen !== isSideControlsOpen) {
            dispatch(setIsSideControlsOpen(isSideControlsOpen));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Persist selected chart failed";
          console.error(errorMessage, e);
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              isSideControlsOpen,
              currentIsSideControlsOpen,
            },
          });
        }
      },
    }),

    setSelectedSideControl: builder.mutation({
      query: ({ userId, scenarioId, selectedSideControl }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(selectedSideControl && {
              selected_side_control: selectedSideControl,
            }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, selectedSideControl },
        { dispatch, getState, queryFulfilled }
      ) {
        if (scenarioId == null) {
          console.error(
            "Cannot call setSelectedSideControl without a scenario id"
          );
        }
        const state = getState();
        const isScenarioReady = state.scenario.isScenarioReady;
        if (!isScenarioReady) {
          console.error(
            "Cannot call setSelectedSideControl while scenario slice is not ready"
          );
        }
        const currentSelectedSideControl = selectSelectedSideControl(state);
        if (isScenarioReady && scenarioId === state.scenario.id) {
          if (currentSelectedSideControl !== selectedSideControl) {
            dispatch(setSelectedSideControl(selectedSideControl));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Persist selected chart failed";
          console.error(errorMessage, e);
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              selectedSideControl,
              currentSelectedSideControl,
            },
          });
        }
      },
    }),

    setMediaBudget: builder.mutation({
      query: ({ userId, scenarioId, mediaBudget }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(mediaBudget && { media_budget: mediaBudget }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, mediaBudget },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const { mediaBudget: currentMediaBudget } = selectScenario(state);
        if (scenarioId === state.scenario.id) {
          if (currentMediaBudget !== mediaBudget) {
            dispatch(setMediaBudget(mediaBudget));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set media budget failed";
          console.error(errorMessage, e);
          dispatch(setMediaBudget(currentMediaBudget));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              mediaBudget,
              currentMediaBudget,
            },
          });
        }
      },
    }),

    setGrowthTarget: builder.mutation({
      query: ({ userId, scenarioId, growthTarget }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(growthTarget && { growth_target: growthTarget }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, growthTarget },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const { growthTarget: currentGrowthTarget } = selectScenario(state);
        if (scenarioId === state.scenario.id) {
          if (currentGrowthTarget !== growthTarget) {
            dispatch(setGrowthTarget(growthTarget));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set growth target failed";
          console.error(errorMessage, e);
          dispatch(setGrowthTarget(currentGrowthTarget));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              growthTarget,
              currentGrowthTarget,
            },
          });
        }
      },
    }),

    setMediaChannelSelection: builder.mutation({
      query: ({ userId, scenarioId, selectedMediaChannels }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(selectedMediaChannels != null && {
              selectedMediaChannels: selectedMediaChannels,
            }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        {
          userId,
          scenarioId,
          selectedMediaChannels,
          updateScenarioSlice = true,
        },
        { dispatch, getState, queryFulfilled }
      ) {
        const patchResult = dispatch(
          scenarioMetadataApi.util.updateQueryData(
            "getScenario",
            { userId, scenarioId },
            (draft) => {
              Object.assign(draft, { selectedMediaChannels });
            }
          )
        );
        const state = getState();
        const { selectedMediaChannels: currentSelectedMediaChannels } =
          selectScenario(state);
        if (updateScenarioSlice && scenarioId === state.scenario.id) {
          dispatch(setSelectedMediaChannels(selectedMediaChannels));
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set selected channels failed";
          console.error(errorMessage, e);
          if (updateScenarioSlice && scenarioId === state.scenario.id) {
            dispatch(setSelectedMediaChannels(currentSelectedMediaChannels));
          }
          patchResult.undo();
          // reset to current selection
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              selectedMediaChannelsLength: selectedMediaChannels.length,
              currentSelectedMediaChannelsLength:
                currentSelectedMediaChannels.length,
            },
          });
        }
      },
    }),
    setMediaChannelSelectionFromTreeNodes: builder.mutation({
      // TODO this errors every time the query is run
      query: null,
      async onQueryStarted(_, { dispatch, getState }) {
        const state = getState();
        const {
          userId,
          id: scenarioId,
          selectedMediaChannels,
        } = selectScenario(state);

        await dispatch(
          scenarioMetadataApi.endpoints.setMediaChannelSelection.initiate({
            userId,
            scenarioId,
            selectedMediaChannels,
          })
        );
      },
    }),

    setMediaChannelExpansionKeys: builder.mutation({
      query: ({ userId, scenarioId, expansionKeys }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(expansionKeys != null && {
              expansionKeys,
            }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, expansionKeys },
        { dispatch, getState, queryFulfilled }
      ) {
        const patchResult = dispatch(
          scenarioMetadataApi.util.updateQueryData(
            "getScenario",
            { userId, scenarioId },
            (draft) => {
              Object.assign(draft, { expansionKeys });
            }
          )
        );
        // const state = getState();
        // const { selectedMediaChannels: currentSelectedMediaChannels } =
        //   selectScenario(state);
        // if (updateScenarioSlice && scenarioId === state.scenario.id) {
        //   dispatch(setSelectedMediaChannels(selectedMediaChannels));
        // }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set expanded channels failed";
          console.error(errorMessage, e);
          // if (updateScenarioSlice && scenarioId === state.scenario.id) {
          //   dispatch(setSelectedMediaChannels(currentSelectedMediaChannels));
          // }
          patchResult.undo();
          // reset to current selection
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              expansionKeys,
            },
          });
        }
      },
    }),
  }),
});

export default scenarioMetadataApi;
export const {
  useGetScenarioQuery,
  useSetMediaHierarchyMutation,
  useSetSalesHierarchyMutation,
  useSetSelectedChartMutation,
  useSetIsSideControlsOpenMutation,
  useSetSelectedSideControlMutation,
  useSetMediaBudgetMutation,
  useSetGrowthTargetMutation,
  useSetMediaChannelSelectionMutation,
  useSetMediaChannelSelectionFromTreeNodesMutation,
  useSetMediaChannelExpansionKeysMutation,
} = scenarioMetadataApi;
