import "ag-grid-enterprise";
import { useIntl } from "react-intl";
import { getFormatters, makeColStates } from "../GridUtils";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetResultsGroupChildrenQuery,
  useLazyGetResultsGroupQuery,
  useLazyGetResultsItemsQuery,
} from "../../app/scenarioApi";
import {
  selectGetMediaTreeNodeData,
  selectGetResultsTreeNodeData,
  selectGetSalesTreeNodeData,
  selectScenario,
  setShouldSetResultsGrid,
  updateMediaTreeNodeData,
  updateResultsTreeNodeData,
  updateSalesTreeNodeData,
} from "../../app/scenarioSlice";
import {
  finalName,
  getChannelDisplayName,
  resultName,
  startingName,
} from "../../constants";
import { isMediaKey } from "../../utils/targetUtils";
import { cellStyle } from "./ConditionalFormatUtils";
import DataGrid, { GridParsingEnum } from "./DataGrid";
import { getImpressionsFormatter } from "./MediaGrid";
import { useSetMediaChannelSelectionFromTreeNodesMutation } from "../../app/scenarioMetadataApi";

const prefixes = [
  { name: startingName, field: "starting" },
  { name: finalName, field: "final" },
  { name: "Incremental", field: "incremental" },
];

function makeDataColumns(name, field, valueFormatter, other) {
  return Array(prefixes.length)
    .fill(undefined)
    .map((_, index) => ({
      headerName: `${prefixes[index].name} ${name}`,
      field: `${prefixes[index].field}_${field}`.toLowerCase(),
      type: "numericColumn",
      valueFormatter,
      ...(prefixes[index].field === "incremental" && {
        valueGetter: (params) => {
          // console.log("incremental getter", params);
          const startingField = `starting_${field}`.toLowerCase();
          const finalField = `final_${field}`.toLowerCase();
          const data = params.node.data;
          if (data[finalField] == null || data[startingField] == null) {
            return null;
          }
          return data[finalField] - data[startingField];
        },
      }),
      ...other,
    }));
}

const carryforwardDateLabel = "Carryforward";

const ResultGrid = ({ gridKey, isInPresentationMode }) => {
  const [gridApi, setGridApi] = useState(null);

  const { isScenarioReady, shouldSetResultsGrid, resultsHierarchy } =
    useSelector(selectScenario);
  const intl = useIntl();

  const formatters = useMemo(() => {
    const formatters = getFormatters(intl);
    formatters.carryforwardDate = (params) => {
      if (params.value === null) return carryforwardDateLabel;
      return formatters.date(params);
    };
    return formatters;
  }, [intl]);

  const agGridColumnItems = useMemo(
    () => [
      ...(resultsHierarchy ?? []).map((field) => ({
        headerName: getChannelDisplayName(field),
        field,
        cellStyle: { textAlign: "left" },
        rowGroup: true,
      })),
      {
        headerName: "Period",
        field: "period",
        cellStyle: { textAlign: "left" },
        valueFormatter: formatters.carryforwardDate,
        minWidth: 220,
      },
      ...makeDataColumns("Spend", "spend", formatters.currencyWithoutCents, {
        cellStyle: (params) =>
          cellStyle(params, ["starting_spend", "final_spend"]),
      }).map((c) =>
        !c.field.includes("incremental")
          ? {
              ...c,
              gridParsingType: GridParsingEnum.Number,
              defaultValue: 0,
            }
          : c
      ),
      ...makeDataColumns("Impressions", "impressions", formatters.decimalZero, {
        valueFormatter: getImpressionsFormatter(formatters.decimalZero),
      }),
      ...makeDataColumns("Adstock", "adstock", formatters.decimalZero),
      ...makeDataColumns("Saturation %", "scurve", formatters.percentZero),
      ...makeDataColumns(
        `${resultName} %`,
        "contribution_pct",
        formatters.percentOnePossible
      ),
      ...makeDataColumns(
        resultName,
        "contribution",
        formatters.currencyWithoutCents,
        {
          cellStyle: (params) =>
            cellStyle(params, ["starting_contribution", "final_contribution"]),
        }
      ),
      ...makeDataColumns("ROI", "roi", formatters.currency, {
        cellStyle: (params) => cellStyle(params, ["starting_roi", "final_roi"]),
      }),
    ],
    [formatters, resultsHierarchy]
  );

  const defaultColState = useMemo(
    () => [
      ...makeColStates(
        "left",
        { colId: "ag-Grid-AutoColumn", hide: false },
        { colId: "period", hide: false }
      ),
      ...makeColStates(
        "",
        ...(resultsHierarchy ?? []).map((key) => ({
          colId: key,
          hide: true,
        })),
        { colId: "starting_spend", hide: false },
        { colId: "final_spend", hide: false },
        { colId: "incremental_spend", hide: true },
        { colId: "starting_impressions", hide: true },
        { colId: "final_impressions", hide: true },
        { colId: "incremental_impressions", hide: true },
        { colId: "starting_adstock", hide: true },
        { colId: "final_adstock", hide: true },
        { colId: "incremental_adstock", hide: true },
        { colId: "starting_scurve", hide: true },
        { colId: "final_scurve", hide: true },
        { colId: "incremental_scurve", hide: true },
        { colId: "starting_contribution_pct", hide: true },
        { colId: "final_contribution_pct", hide: true },
        { colId: "incremental_contribution_pct", hide: true },
        { colId: "starting_contribution", hide: false },
        { colId: "final_contribution", hide: false },
        { colId: "incremental_contribution", hide: true },
        { colId: "starting_roi", hide: false },
        { colId: "final_roi", hide: false },
        { colId: "incremental_roi", hide: true }
      ),
    ],
    [resultsHierarchy]
  );

  const [getResultsGroupChildren, ,] = useLazyGetResultsGroupChildrenQuery();
  const [getResultsGroup, ,] = useLazyGetResultsGroupQuery();
  const [getResultsItems, ,] = useLazyGetResultsItemsQuery();

  const dispatch = useDispatch();

  const getResultsTreeNodeData = useSelector(selectGetResultsTreeNodeData);
  const getMediaTreeNodeData = useSelector(selectGetMediaTreeNodeData);
  const getSalesTreeNodeData = useSelector(selectGetSalesTreeNodeData);

  useEffect(() => {
    if (gridApi == null || !shouldSetResultsGrid) return;
    gridApi.refreshServerSide({ route: [], purge: true });
    dispatch(setShouldSetResultsGrid(false));
  }, [gridApi, shouldSetResultsGrid, dispatch]);

  const getIsNodeExpanded = useCallback(
    (shortTarget) => {
      const { expanded } = getResultsTreeNodeData(shortTarget);
      return expanded;
    },
    [getResultsTreeNodeData]
  );

  const getIsNodeSelected = useCallback(
    (shortTarget, currentKey) => {
      const getNodeData = isMediaKey(currentKey)
        ? getMediaTreeNodeData
        : getSalesTreeNodeData;
      const { selected } = getNodeData(shortTarget);
      return selected;
    },
    [getMediaTreeNodeData, getSalesTreeNodeData]
  );

  const updateIsNodeExpanded = useCallback(
    (shortTarget, expanded) => {
      dispatch(
        updateResultsTreeNodeData({
          shortTarget,
          data: {
            expanded,
          },
        })
      );
    },
    [dispatch]
  );

  const [setMediaChannelSelectionFromTreeNodes] =
    useSetMediaChannelSelectionFromTreeNodesMutation();

  const updateIsNodeSelected = useCallback(
    (shortTarget, selected, currentKey) => {
      if (isMediaKey(currentKey)) {
        dispatch(
          updateMediaTreeNodeData({
            shortTarget,
            data: {
              selected,
            },
            updateDescendents: true,
          })
        );
        setMediaChannelSelectionFromTreeNodes();
        return;
      }
      dispatch(
        updateSalesTreeNodeData({
          shortTarget,
          data: {
            selected,
          },
          updateDescendents: true,
        })
      );
    },
    [dispatch]
  );

  return (
    <DataGrid
      id={"results-grid"}
      gridKey={gridKey}
      hierarchy={resultsHierarchy ?? []}
      colStateKey={"results_col_state"}
      rowStateKey={"results_row_state"}
      agGridColumnItems={agGridColumnItems}
      defaultColState={defaultColState}
      getIsNodeExpanded={getIsNodeExpanded}
      getIsNodeSelected={getIsNodeSelected}
      updateIsNodeExpanded={updateIsNodeExpanded}
      updateIsNodeSelected={updateIsNodeSelected}
      gridOptions={{
        // groupIncludeTotalFooter: true,
        isRowSelectable: (node) => node.group,
      }}
      isInPresentationMode={isInPresentationMode}
      getGroupChildren={getResultsGroupChildren}
      getGroup={getResultsGroup}
      getItems={getResultsItems}
      checkbox={true}
      gridApi={gridApi}
      setGridApi={setGridApi}
    />
  );
};

export default ResultGrid;
