import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Box,
  FilterOptionsState,
  IconButton,
  SxProps,
  TextField,
  createFilterOptions,
} from "@mui/material";
import {
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { Channel } from "../../types/channel";
import { sortChannels as defaultSortChannels } from "../../utils/sortChannels";
import {
  Option,
  areIdListsEqual,
  getHeaderOptionParts,
  getOptionParts,
  isGroupOptionSelected,
  toOptions,
} from "./options";

export const errorDelay = 750;

const contractAllId = "ExpansionControl:contract-all,";
const contractAllOptionStub: Option = {
  name: "",
  id: contractAllId,
  channel: {},
  parentId: "",
  parentChannel: {},
  depth: 0,
  isGroup: false,
  matchTerms: [],
  matchName: "",
  ancestorsIds: [],
  descendantsIds: [],
};
const headerId = "ExpansionControl:header,";

function ChannelItem({
  option,
  liProps,
  expanded,
  setExpansion,
  sx,
  children,
}: {
  option: Option;
  liProps?: HTMLAttributes<HTMLLIElement>;
  expanded: boolean;
  setExpansion: (expansion: boolean) => void;
  sx?: SxProps;
  children: JSX.Element;
}) {
  return (
    <Box
      component="li"
      key={option.id}
      data-id={option.id}
      data-parent-id={option.parentId}
      {...liProps}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setExpansion(!expanded);
      }}
      sx={sx}
      aria-selected={false}
    >
      <Box sx={{ ml: 2 * option.depth }} />
      {option.isGroup ? (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setExpansion(!expanded);
          }}
        >
          <ExpandMoreIcon
            sx={{
              transform: !expanded ? "rotate(-90deg)" : "rotate(0deg)",
            }}
          />
        </IconButton>
      ) : (
        // option.id === selectAllId ? <></> : <Box sx={{ width: "40px" }} />
        <Box sx={{ width: "40px", height: "40px" }} />
      )}
      {children}
    </Box>
  );
}

function handleScroll(
  scrollContainer: HTMLUListElement,
  setCurrentHeaderId: Dispatch<SetStateAction<string>>
) {
  // console.log("ListboxComponent handleScroll", ref.current);
  if (scrollContainer == null) {
    // console.log("handleScroll scrollContainer is NULL");
    return;
  }
  // console.log(scrollContainer, scrollContainer?.querySelectorAll("li"))
  const containerTop = scrollContainer.getBoundingClientRect().top;
  const scrollItems = scrollContainer.querySelectorAll<HTMLLIElement>("li");
  // const rect = scrollItems[1].getBoundingClientRect();

  let isSecondChannelItem = true;
  for (const scrollItem of Array.from(scrollItems)) {
    if (
      scrollItem.dataset.id === contractAllId ||
      scrollItem.dataset.id === headerId
    ) {
      continue;
    }
    const scrollItemRect = scrollItem.getBoundingClientRect();
    const scrollItemTop = scrollItemRect.top;
    // const scrollItemHeight = scrollItemRect.height;
    // const scrollItemBottom = scrollItem.getBoundingClientRect().bottom;
    if (scrollItemTop - 16 > containerTop) {
      if (isSecondChannelItem) {
        setCurrentHeaderId(null);
      } else if (scrollItem.dataset.parentId) {
        setCurrentHeaderId(scrollItem.dataset.parentId);
      }
      break;
    }
    isSecondChannelItem = false;
  }
}

const ListboxComponent = ({
  children,
  getHeaderOption,
  filteredIds, // for UE trigger only
  ...other
}: HTMLAttributes<HTMLUListElement> & {
  getHeaderOption: (id: string) => ReactNode;
  filteredIds: string[];
}) => {
  // console.log("ListboxComponent rerender");
  const [currentHeaderId, setCurrentHeaderId] = useState<string | null>(null);

  // useEffect(() => {
  //   console.log("currentHeaderId", currentHeaderId);
  // }, [currentHeaderId]);

  const ref = createRef<HTMLUListElement>();

  useLayoutEffect(() => {
    const scrollContainer = ref.current;

    // console.log("ListboxComponent UE", ref.current);
    function handleScrollWrapper() {
      handleScroll(scrollContainer, setCurrentHeaderId);
    }

    // console.log("ref", ref.current);
    // console.log("scrollContainer", scrollContainer);
    if (scrollContainer) {
      // console.log("ATTACH");
      scrollContainer.addEventListener("scroll", handleScrollWrapper);
    } else {
      // console.log("ATTACH FAIL");
    }

    return () => {
      // console.log("DETACH");
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScrollWrapper);
      } else {
        // console.log("DETACH FAIL");
      }
    };
  }, []);

  useEffect(() => {
    // console.log("filteredIds", ref.current)
    handleScroll(ref.current, setCurrentHeaderId);
  }, [filteredIds]);

  const [selectAll, ...optionChildren] = children as ReactNode[];

  const headerNode =
    currentHeaderId != null ? getHeaderOption(currentHeaderId) : null;

  return (
    <ul {...other} ref={ref}>
      {headerNode ?? selectAll}
      {optionChildren}
    </ul>
  );
};

export type ExpansionControlProps = {
  channelsKey: string;
  label: string;
  initialValue: Channel[];
  getIsChannelExpanded: (channel: Channel) => boolean;
  updateIsChannelExpanded: (channel: Channel, expanded: boolean) => void;
  allChannels: Channel[];
  hierarchy: string[];
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  isLoading: boolean;
  onClose?: () => void;
  isContractAllDisabled?: boolean;
  handleContractAll: () => void;
  sortChannels?: (channels: Channel[], hierarchy: string[]) => Channel[];
  expandedCount: number;
};

function ExpansionControl({
  channelsKey,
  label,
  initialValue,
  getIsChannelExpanded,
  updateIsChannelExpanded,
  allChannels: _allChannels,
  hierarchy: _hierarchy,
  errors,
  setErrors,
  isLoading,
  onClose,
  isContractAllDisabled = false,
  handleContractAll,
  sortChannels: overrideSortChannels,
  expandedCount,
}: ExpansionControlProps) {
  const sortChannels = overrideSortChannels ?? defaultSortChannels;
  const [open, setOpen] = useState(false);
  const filter = useMemo(
    () =>
      createFilterOptions<Option>({
        stringify: (o) => o.matchName,
      }),
    []
  );
  const [inputValue, setInputValue] = useState("");
  const hierarchy = useMemo(() => _hierarchy ?? [], [_hierarchy]);
  const allChannels = useMemo(
    () =>
      !isLoading && _allChannels != null && _allChannels.length !== 0
        ? sortChannels(_allChannels.slice(), hierarchy)
        : [],
    [_allChannels, isLoading]
  );
  const allOptions = useMemo(
    () => (!isLoading ? toOptions(allChannels, hierarchy) : []) ?? [],
    [allChannels, isLoading, hierarchy]
  );

  const [filteredIds, setFilteredIds] = useState(
    allOptions.filter(({ isGroup }) => !isGroup).map(({ id }) => id) as string[]
  );

  useEffect(() => {
    // null -> empty; undefined -> initial value not used
    if (initialValue === undefined) return;
    // setExpansion(initialValue);
  }, [initialValue]);

  const wasOpen = useRef(false);
  useEffect(() => {
    if (open === true) {
      wasOpen.current = true;
      return;
    }
    if (wasOpen.current && open === false) {
      wasOpen.current = false;
      onClose?.();
    }
  }, [open]);

  // useEffect(() => {
  //   console.log("filteredIds", filteredIds);
  // }, [filteredIds]);
  // useEffect(() => {
  //   console.log("allOptions", allOptions);
  // }, [allOptions]);

  const [recentlyEditedChannels, setRecentlyEditedChannels] = useState(false);
  const debouncedClearRecentlyEditedChannels = useDebouncedCallback(
    () => setRecentlyEditedChannels(false),
    errorDelay
  );

  const updateFilteredOptions = useDebouncedCallback((filtered) => {
    // console.log("debounce");
    setFilteredIds((prev) => {
      if (areIdListsEqual(prev, filtered)) {
        return prev;
      }
      return filtered;
    });
  }, 250);

  // TODO fix filtering

  function filterOptions(options: Option[], state: FilterOptionsState<Option>) {
    // console.log("filterOptions");
    const defaultFiltered = filter(options, state);
    const filteredIds = defaultFiltered.map(({ id }) => id);
    const filtered = options.filter(
      (o) =>
        filteredIds.findIndex(
          (id) =>
            o.id === id ||
            o.ancestorsIds.includes(id) ||
            o.descendantsIds.includes(id)
        ) !== -1
    );

    const newFilteredIds = filtered
      .filter(({ isGroup }) => !isGroup)
      .map(({ id }) => id);
    updateFilteredOptions(newFilteredIds);

    if (filtered.length === 0) {
      return [];
    }

    return [contractAllOptionStub, ...filtered];
  }

  const getHeaderOption = useCallback(
    (id: string) => {
      const headerMatchChildOption: Option | null = id
        ? allOptions.find((o) => o.id === id) ?? null
        : null;
      const headerMatchOptionId =
        headerMatchChildOption?.matchTerms
          .slice(0, -1)
          .filter((v) => v != null)
          .join(",") ?? null;
      const headerMatchOption: Option | null = headerMatchOptionId
        ? allOptions.find((o) => o.id === headerMatchOptionId) ?? null
        : null;

      const partsList = getHeaderOptionParts(headerMatchOption, inputValue);

      return (
        <ChannelItem
          key={headerId}
          option={{
            ...headerMatchOption,
            id: headerId,
            isGroup: false,
            depth: 0,
          }}
          sx={{
            zIndex: 2, // above other option checkboxes
            position: "sticky",
            top: "-8px",
            padding: "0 4px 0 16px",
            boxShadow: "0 1px 0 rgba(0, 0, 0, 0.12)",
            backgroundColor: "white",
            cursor: "auto!important",
          }}
          liProps={{
            tabIndex: -1,
            role: "option",
            className: "MuiAutocomplete-option",
          }}
          expanded={false} // doesn't matter since not group
          setExpansion={() => {}} // doesn't matter since not group
        >
          <Box
            sx={{
              display: "flex",
              whiteSpace: "pre-wrap",
            }}
          >
            {partsList.flatMap((parts, index) => {
              const s = parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ));

              return index === partsList.length - 1
                ? s
                : [s, <ChevronRightIcon key={index + "chevron"} />];
            })}
          </Box>
        </ChannelItem>
      );
    },
    [allOptions, filteredIds, inputValue]
  );

  // used by renderOption every render
  const renderedAncestors: string[] = [];
  function addExpandedAncestor(id: string) {
    renderedAncestors.push(id);
  }
  function isAncestorRendered(id: string) {
    return renderedAncestors.indexOf(id) !== -1;
  }

  return (
    <Autocomplete
      id={`${channelsKey}-channels-autocomplete`}
      disableListWrap
      clearOnEscape
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={allOptions}
      value={useMemo(
        () => [
          ...allOptions.filter((option) =>
            option.isGroup
              ? isGroupOptionSelected(option, filteredIds)
              : filteredIds.includes(option.id)
          ),
        ],
        [allOptions, filteredIds]
      )}
      inputValue={inputValue}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, option: Option, { selected, inputValue }) => {
        const isNotTopLevel = option.ancestorsIds.length !== 0;
        if (isNotTopLevel) {
          const parentId = option.ancestorsIds[0];
          if (
            !getIsChannelExpanded(option.parentChannel) ||
            !isAncestorRendered(parentId)
          ) {
            return null;
          }
        }
        if (option.id !== contractAllId) {
          addExpandedAncestor(option.id);
        }

        // @ts-ignore key does not exist
        const { key: _, ...safeProps } = props;

        // contract all is converted to the header when needed
        // this solves the issue off scroll length changing with a conditional sticky header
        // a fixed header has sizing issues
        if (option.id === contractAllId) {
          return (
            <Box
              component="li"
              key={option.id}
              data-id={option.id}
              {...safeProps}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleContractAll();
              }}
              aria-disabled={isContractAllDisabled}
            >
              <Box sx={{ width: "40px", height: "40px" }} />
              <div>(Contract All)</div>
            </Box>
          );
        }

        const parts = getOptionParts(option, inputValue);

        return (
          <ChannelItem
            option={option}
            liProps={safeProps}
            expanded={getIsChannelExpanded(option.channel)}
            setExpansion={(expansion) => {
              updateIsChannelExpanded(option.channel, expansion);
            }}
          >
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </ChannelItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label + ` (${expandedCount} expanded)`}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(!recentlyEditedChannels && errors[channelsKey])}
          helperText={
            (!recentlyEditedChannels ? errors[channelsKey] : undefined) || " "
          }
          placeholder="Search"
          value={inputValue}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              // stop deletion of tags
              event.stopPropagation();
            }
          }}
        />
      )}
      ListboxComponent={ListboxComponent}
      // @ts-ignore
      ListboxProps={{ getHeaderOption, filteredIds }}
      renderTags={() => null}
      filterOptions={filterOptions}
      disableCloseOnSelect
      multiple
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          // ignore other reasons that clear the input
          setInputValue(value);
        }
      }}
      clearIcon={null}
    />
  );
}

export default ExpansionControl;
