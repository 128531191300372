import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { zeroTimeString } from "../constants";
import { sortChannels } from "../utils/sortChannels";
import { endpointsUrl } from "./endpoints";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: ({ companyId }) => ({
        url: "company/get",
        method: "POST",
        body: {
          company_id: companyId,
        },
      }),
      transformResponse: (responseData) => {
        // convert naming scheme of some returned fields
        const {
          default_start_date,
          default_end_date,
          media_channels,
          sales_channels,
          ...other
        } = responseData.data;
        return {
          ...other,
          defaultStartDate: default_start_date + zeroTimeString,
          defaultEndDate: default_end_date + zeroTimeString,
          mediaChannels: sortChannels(media_channels, other.mediaHierarchy),
          salesChannels: sortChannels(sales_channels, other.salesHierarchy),
        };
      },
      providesTags: (_result, _error, { companyId }) => {
        return [{ type: "Company", id: companyId }];
      },
    }),
    getCompanyNames: builder.query({
      query: ({ userId, companyIds }) => ({
        url: "companies/getNames",
        method: "POST",
        body: {
          user_id: userId,
          companyIds,
        },
      }),
      transformResponse: (responseData) => {
        return responseData.data.map((d) => ({
          isDeletable: d.isDeletable,
          companyId: d.company_id,
          name: d.name,
        }));
      },
    }),
  }),
});

export default companyApi;
export const { useGetCompanyQuery, useGetCompanyNamesQuery } = companyApi;
