import { categoryVectorToCategoryName } from "../containers/CategoryDataUtils";
import { objectFilter } from "./objectUtil";

export class TargetError extends Error {
  constructor(message) {
    super(message);
    this.name = "TargetError";
  }
}

export class HierarchyError extends TargetError {
  constructor(message) {
    super(message);
    this.name = "TargetKeyError";
  }
}

export class TargetKeyError extends TargetError {
  constructor(message) {
    super(message);
    this.name = "TargetKeyError";
  }
}

export const mediaKeyPrefix = "media_channel_";
export const salesKeyPrefix = "sales_channel_";

function getKey(prefix, i) {
  if (i < 1)
    throw new TargetKeyError("Cannot create keys with numbers less than one");
  return prefix + i;
}
export function mc(index) {
  return getKey(mediaKeyPrefix, index);
}
export function sc(index) {
  return getKey(salesKeyPrefix, index);
}

export function isMediaKey(k) {
  return k.startsWith(mediaKeyPrefix);
}

export function isSalesKey(k) {
  return k.startsWith(salesKeyPrefix);
}

export function isHierarchyKey(k) {
  return isMediaKey(k) || isSalesKey(k);
}

// filter out non-target keys from an object
export function getTargetFromObject(o) {
  return objectFilter(o, ([k]) => isHierarchyKey(k));
}

function getDefaultKeys(prefix, length) {
  return Array(length)
    .fill(prefix)
    .map((v, i) => v + (i + 1));
}

export function getDefaultMediaKeys(length) {
  return getDefaultKeys(mediaKeyPrefix, length);
}

export function getDefaultSalesKeys(length) {
  return getDefaultKeys(salesKeyPrefix, length);
}

export function getNewTarget(target, keys) {
  return keys.reduce(
    (acc, k) => ({
      ...acc,
      [k]: target[k] ?? null,
    }),
    {}
  );
}

export function keysMatchUnordered(keys1, keys2) {
  if (keys1.length !== keys2.length) return false;
  // use sets so a duplicate key in a hierarchy is handles
  const keySet1 = new Set(keys1);
  const keySet2 = new Set(keys2);
  if (keySet1.size !== keySet2.size) return false;
  return [...keySet1].every((k) => keySet2.has(k));
}

export function getDefaultHierarchyFromTarget(target) {
  return getDefaultMediaKeys(Object.keys(target).length);
}

export function getSplitHierarchy(hierarchy) {
  const media = hierarchy.filter(isMediaKey);
  const sales = hierarchy.filter(isSalesKey);
  return { media, sales };
}

export function getSplitTarget(target) {
  const media = objectFilter(target, ([k]) => isMediaKey(k));
  const sales = objectFilter(target, ([k]) => isSalesKey(k));
  return { media, sales };
}

export function throwIfHierarchyIsInvalid(
  hierarchy,
  isResultsHierarchy = false
) {
  // TODO check for duplicate keys
  if (hierarchy.length <= 0) {
    throw new HierarchyError("Hierarchy must have at lease one channel");
  }
  const splitHierarchy = getSplitHierarchy(hierarchy);
  if (isResultsHierarchy) {
    if (splitHierarchy.media.length <= 0) {
      throw new HierarchyError(
        "Hierarchy must have at lease one media channel"
      );
    }
    if (splitHierarchy.sales.length <= 0) {
      throw new HierarchyError(
        "Hierarchy must have at lease one sales channel"
      );
    }
  }
  const { hasSalesAboveMedia } = hierarchy.reduce(
    (acc, k) => ({
      ...acc,
      ...(isSalesKey(k) && { inSales: true }),
      ...(acc.inSales && isMediaKey(k) && { hasSalesAboveMedia: true }),
    }),
    { inSales: false, hasSalesAboveMedia: false }
  );
  if (hasSalesAboveMedia) {
    throw new HierarchyError(
      "Hierarchy cannot have a sales channel above a media channel"
    );
  }

  if (
    !getDefaultMediaKeys(splitHierarchy.media.length).every((k) =>
      splitHierarchy.media.includes(k)
    )
  ) {
    throw new HierarchyError(
      "Hierarchy cannot have media keys beyond its length"
    );
  }
  if (
    !getDefaultSalesKeys(splitHierarchy.sales.length).every((k) =>
      splitHierarchy.sales.includes(k)
    )
  ) {
    throw new HierarchyError(
      "Hierarchy cannot have sales keys beyond its length"
    );
  }
}

export function throwOnTargetHierarchyMismatch(hierarchy, target) {
  const targetKeys = Object.keys(target);
  if (!targetKeys.every((k) => hierarchy.includes(k))) {
    throw new HierarchyError(
      "Target cannot have keys not included in its hierarchy"
    );
  }

  const splitHierarchy = getSplitHierarchy(hierarchy);
  const splitTarget = getSplitTarget(target);
  const mediaTargetKeys = Object.keys(splitTarget.media);
  const salesTargetKeys = Object.keys(splitTarget.sales);
  const slicedHierarchyMediaKeys = splitHierarchy.media.slice(
    0,
    mediaTargetKeys.length
  );
  const slicedHierarchySalesKeys = splitHierarchy.sales.slice(
    0,
    salesTargetKeys.length
  );
  if (
    !keysMatchUnordered(mediaTargetKeys, slicedHierarchyMediaKeys) ||
    !keysMatchUnordered(salesTargetKeys, slicedHierarchySalesKeys)
  ) {
    throw new HierarchyError("Target cannot skip hierarchy keys");
  }
}

export function getShortHierarchyFromShortTarget(hierarchy, target) {
  throwOnTargetHierarchyMismatch(hierarchy, target);
  const result = hierarchy.slice(0, Object.keys(target).length);
  throwOnTargetHierarchyMismatch(result, target);
  return result;
}

// keysEnd can be negative to start from end of the targets keys
export function shrinkTarget(hierarchy, target, keysEnd) {
  const targetHierarchy = getShortHierarchyFromShortTarget(hierarchy, target);
  const newKeys = targetHierarchy.slice(0, keysEnd);
  return getNewTarget(target, newKeys);
}

export function areTargetsEqual(a, b) {
  const targetA = getTargetFromObject(a);
  const targetB = getTargetFromObject(b);

  if (!keysMatchUnordered(Object.keys(targetA), Object.keys(targetB))) {
    return false;
  }

  for (const k in targetA) {
    if (targetA[k] !== targetB[k]) {
      return false;
    }
  }

  return true;
}

// does not care about order
export function areTargetListsEqual(listA, listB) {
  if (listA.length !== listB.length) return false;

  for (const a of listA) {
    let foundEqual = false;

    for (const b of listB) {
      if (areTargetsEqual(a, b)) {
        foundEqual = true;
        break;
      }
    }

    if (!foundEqual) return false;
  }

  return true;
}

export function isSuperTarget(candidate, other) {
  const candidateKeys = Object.keys(getTargetFromObject(candidate));
  const otherKeys = Object.keys(getTargetFromObject(other));
  if (candidateKeys.length > otherKeys.length) {
    return false;
  }
  if (!candidateKeys.every((k) => otherKeys.includes(k))) {
    return false;
  }
  for (const k of candidateKeys) {
    if (candidate[k] !== other[k]) {
      return false;
    }
  }
  return true;
}

export function getNameWithoutDuplicates(target, keys) {
  const channel = keys.map((k) => target[k]);
  const seenChannelNames = new Set();
  const filteredChannel = channel.filter((name) => {
    if (seenChannelNames.has(name)) {
      return false;
    }
    seenChannelNames.add(name);
    return true;
  });

  return categoryVectorToCategoryName(filteredChannel);
}

export function getTargetWithoutDuplicates(target) {
  const seenChannelNames = new Set();
  return objectFilter(target, ([_, name]) => {
    if (seenChannelNames.has(name)) {
      return false;
    }
    seenChannelNames.add(name);
    return true;
  });
}
