import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetMediaGroupsQuery } from "../../app/scenarioApi";
import { useGetScenarioChannelsQuery } from "../../app/scenarioChannelsApi";
import { useSetMediaChannelSelectionMutation } from "../../app/scenarioMetadataApi";
import {
  resetResultsTreeExpansion,
  selectCanContractMediaChannels,
  selectExpandedShortMediaTargetsWithoutShortCircuit,
  selectGetResultsTreeNodeData,
  selectScenario,
  updateResultsTreeNodeData,
} from "../../app/scenarioSlice";
import ChannelSelect from "../../components/ChannelSelect/ChannelSelect";
import ExpansionControl from "../../components/ExpansionControl/ExpansionControl";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import { Channel } from "../../types/channel";
import { getTargetFromObject } from "../../utils/targetUtils";

function SelectionAndExpansionControls() {
  const {
    userId,
    id: scenarioId,
    isScenarioReady,
    mediaHierarchy,
    selectedMediaChannels,
  } = useSelector(selectScenario);

  const [mediaChannels, setMediaChannels] = useState([]);

  const [errors, setErrors] = useState({});

  const [setSelectedMediaChannels] = useSetMediaChannelSelectionMutation();

  const {
    data: scenarioChannels,
    isLoading: scenarioChannelsAreLoading,
    isFetching: scenarioChannelsAreFetching,
    isSuccess: scenarioChannelsAreSuccess,
  } = useGetScenarioChannelsQuery(
    {
      scenarioId,
      userId,
    },
    {
      skip: scenarioId == null || userId == null,
    }
  );

  const isLoadingChannels = useMemo(
    () =>
      scenarioChannelsAreLoading ||
      !scenarioChannelsAreSuccess ||
      scenarioChannelsAreFetching,
    [
      scenarioChannelsAreLoading,
      scenarioChannelsAreSuccess,
      scenarioChannelsAreFetching,
    ]
  );

  const {
    data: sortedMedia,
    isLoading: sortedMediaIsLoading,
    isFetching: sortedMediaIsFetching,
    isSuccess: sortedMediaIsSuccess,
  } = useGetMediaGroupsQuery(
    {
      scenarioId,
      userId,
      targets: scenarioChannels?.media,
      hierarchy: mediaHierarchy,
    },
    {
      skip: !isScenarioReady || isLoadingChannels || scenarioChannels == null,
    }
  );

  const sortedTargets = useMemo(
    () => sortedMedia?.map(getTargetFromObject) ?? null,
    [sortedMedia]
  );

  const isSortedTargetsReady = useMemo(
    () =>
      sortedTargets != null &&
      !sortedMediaIsLoading &&
      !sortedMediaIsFetching &&
      sortedMediaIsSuccess,
    [
      sortedTargets,
      sortedMediaIsLoading,
      sortedMediaIsFetching,
      sortedMediaIsSuccess,
    ]
  );

  const dispatch = useDispatch();

  const getResultsTreeNodeData = useSelector(selectGetResultsTreeNodeData);

  const getIsChannelExpanded = useCallback(
    (shortTarget: Channel) => {
      if (Object.keys(shortTarget).length === 0) {
        return true;
      }
      const { expanded } = getResultsTreeNodeData(shortTarget);
      return expanded;
    },
    [getResultsTreeNodeData]
  );

  const canContractMediaChannels = useSelector(selectCanContractMediaChannels);

  const updateIsChannelExpanded = useCallback(
    (shortTarget: Channel, expanded: boolean) => {
      dispatch(
        updateResultsTreeNodeData({
          shortTarget,
          data: {
            expanded,
          },
        })
      );
    },
    [dispatch]
  );

  const expandedMediaChannels = useSelector(
    selectExpandedShortMediaTargetsWithoutShortCircuit
  );

  const expandedMediaChannelsCount = expandedMediaChannels.length;

  return (
    // @ts-ignore
    <LoadingSection
      isLoading={!isScenarioReady || !isSortedTargetsReady}
      type={LoadingType.Hide}
    >
      <Box sx={{ mx: 4, my: 1 }}>
        <Box sx={{ py: 1 }}>
          <ChannelSelect
            channelsKey="mediaChannels"
            label="Select Channels"
            initialValue={selectedMediaChannels}
            channels={mediaChannels}
            setChannels={(newSelection) => {
              setMediaChannels(newSelection);
              setSelectedMediaChannels({
                userId,
                scenarioId,
                selectedMediaChannels: newSelection,
              });
            }}
            allChannels={sortedTargets}
            hierarchy={mediaHierarchy}
            errors={errors}
            setErrors={setErrors}
            isLoading={isLoadingChannels}
            // sorted channels passed in
            sortChannels={(channels) => channels}
          />
          <ExpansionControl
            channelsKey="mediaExpansion"
            label="Expand Channels"
            initialValue={sortedTargets}
            getIsChannelExpanded={getIsChannelExpanded}
            updateIsChannelExpanded={updateIsChannelExpanded}
            allChannels={sortedTargets}
            hierarchy={mediaHierarchy}
            errors={errors}
            setErrors={setErrors}
            isLoading={isLoadingChannels}
            isContractAllDisabled={!canContractMediaChannels}
            // all or results tree is reset
            // whereas only the media expansion is persisted
            handleContractAll={() => dispatch(resetResultsTreeExpansion())}
            sortChannels={(channels) => channels}
            expandedCount={expandedMediaChannelsCount}
          />
        </Box>
      </Box>
    </LoadingSection>
  );
}

export default SelectionAndExpansionControls;
